/* eslint-disable object-curly-newline */
import React, { useContext, useMemo } from 'react';
import { isEmpty } from 'lodash';
import Error from '../../components/alerts/Error';
import SizeComplianceFacets from './facets/SizeComplianceFacets';
import SizeComplianceTable from './summaryTable/SizeComplianceTable';
import { LoadingIndicator } from '../../assets/Svgs';
import useAuthorization from '../../components/authorizer/Authorizer';
import useTutorial from '../../hooks/useTutorial';

import { SimWebContext } from '../../context/SimWeb.provider';
import { SizeComplianceContext } from './SizeCompliance.provider';

import './SizeComplianceReport.css';

const SizeCompliancePage = () => {
  const title = 'sizeCompliance';

  const { getMessage } = useContext(SimWebContext);
  const { loading, error, refetch, data, isInitialCall } = useContext(SizeComplianceContext);
  const { getTutorial } = useTutorial(title);
  const { isAuthorized, unauthorizedView: UnauthorizedView } = useAuthorization(title, 'sizeComplianceEnabled');

  const tutorialToggle = useMemo(() => getTutorial(loading || isInitialCall || isEmpty(data)), [data, isInitialCall, loading]);

  const content = useMemo(() => {
    if (loading && isInitialCall) return <div data-testid="loadingSizeComplianceData" className="sizeCompliance-loading-spinner"><LoadingIndicator /></div>;

    if (error) {
      return (
        <Error
          apiName="Size Compliance API"
          errorObject={error}
          pageCode=""
        />
      );
    }

    return <SizeComplianceTable />;
  }, [loading, refetch, error, data]);

  if (!isAuthorized) {
    return UnauthorizedView;
  }

  return (
    <div className="size-compliance-container" data-testid="sizeComplianceReport">
      <div className="size-compliance-body">
        <div className="size-compliance-facets">
          <div className="feature-title" id="scan-report-title">
            {getMessage(title)}
          </div>
          {tutorialToggle}
          <SizeComplianceFacets />
        </div>
        <div className="size-compliance-table">
          {content}
        </div>
      </div>
    </div>
  );
};

export default SizeCompliancePage;
