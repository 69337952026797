import React, { useMemo, useState } from 'react';
import {
  bool, func, number, object, string,
} from 'prop-types';
import { Dialog } from '@mui/material';
import { LoadingIndicator } from '../../../../assets/Svgs';
import ConfirmationModal from './confirmationModal/ConfirmationModal';
import ScanInfo from './info/ScanInfo';
import ManagerApproval from './managerApproval/ManagerApproval';
import { scanTypesLabel } from '../../scanReportConstants';

import './DetailsWidgetContainer.css';

const DetailsWidgetContainer = ({
  accuracySubmissionThreshold, scanInfoAggregations, currencyCode, data, formatMessage, getMessage, isOffsiteEnabled, isScanNotProccessing,
  loading, locale, nikeStoreRoles, scanProgress, SimDateTime,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [approvalAction, setApprovalAction] = useState('');

  const widget = useMemo(() => {
    if (loading) {
      return (
        <div className="details-widget-loading" data-testid="details-widget-loading">
          <LoadingIndicator width="65px" height="65px" fill="#FFFFFF" />
        </div>
      );
    }
    return (
      <>
        <h4 className="details-widget-title">
          { !data?.scanType ? '' : `${getMessage(scanTypesLabel[data.scanType])} ${getMessage('scanDetailsStatus')}`}
        </h4>
        <ManagerApproval
          accuracySubmissionThreshold={accuracySubmissionThreshold}
          data={data ?? {}}
          getMessage={getMessage}
          formatMessage={formatMessage}
          isScanNotProccessing={isScanNotProccessing}
          nikeStoreRoles={nikeStoreRoles}
          scanProgress={scanProgress}
          setApprovalAction={setApprovalAction}
          setOpenModal={setOpenModal}
        />
        <ScanInfo
          data={data}
          scanInfoAggregations={scanInfoAggregations}
          getMessage={getMessage}
          isOffsiteEnabled={isOffsiteEnabled}
          locale={locale}
          currencyCode={currencyCode}
          SimDateTime={SimDateTime}
        />
      </>
    );
  }, [
    accuracySubmissionThreshold, data, getMessage, isOffsiteEnabled, isScanNotProccessing, locale, nikeStoreRoles, scanProgress, scanInfoAggregations,
  ]);

  return (
    <div className="details-widget-container widget" data-testid="details-widget-container">
      <div className="details-widget">
        {widget}
      </div>
      <Dialog
        open={openModal}
        fullScreen={false}
        maxWidth="md"
        fullWidth
      >
        <ConfirmationModal
          approvalAction={approvalAction}
          setOpenModal={setOpenModal}
          setApprovalAction={setApprovalAction}
        />
      </Dialog>
    </div>
  );
};

DetailsWidgetContainer.propTypes = {
  accuracySubmissionThreshold: number,
  scanInfoAggregations: object.isRequired,
  currencyCode: string,
  data: object,
  formatMessage: func.isRequired,
  getMessage: func.isRequired,
  isOffsiteEnabled: bool,
  isScanNotProccessing: bool,
  loading: bool,
  locale: string,
  nikeStoreRoles: object.isRequired,
  scanProgress: number.isRequired,
  SimDateTime: object.isRequired,
};

DetailsWidgetContainer.defaultProps = {
  accuracySubmissionThreshold: 0,
  currencyCode: 'USD',
  data: null,
  isOffsiteEnabled: false,
  isScanNotProccessing: false,
  loading: false,
  locale: 'en-US',
};

export default DetailsWidgetContainer;
