/* eslint-disable no-console */
/* eslint-disable max-len */
import React, {
  useContext, useEffect, useMemo, useState,
} from 'react';
import Dialog from '@mui/material/Dialog';
import { isEmpty } from 'lodash';
import Alert from '../../components/alerts/Alert';
import { LoadingIndicator } from '../../assets/Svgs/index';
import PhysicalInventoryUploadVarianceTable from './PhysicalInventoryUploadVarianceTable';
import PhysicalInventoryUploadSummary from './PhysicalInventoryUploadSummary';
import DragDropFileUploader from './DragDropFileUploader';
import InformationalModal from '../../components/informationalModal/InformationalModal';
import useAuthorization from '../../components/authorizer/Authorizer';
import Tutorial from '../../components/tutorial/Tutorial';
import { setPIUploadTutorialSteps, toExcel } from './physicalInventoryUpload.utils';

import PIUploadStatus from './PIUploadStatus';
import { newRelicAction } from '../../utils/newRelicPageActions';
import { wait } from '../../axios/axiosFunctions';

import { PhysicalInventoryUploadContext } from './PhysicalInventoryUpload.provider';
import { SimWebContext } from '../../context/SimWeb.provider';
import { tutorialContext } from '../../context/Tutorial.provider';

import './PhysicalInventoryUploadPage.css';

const PhysicalInventoryUpload = () => {
  const {
    storeId, getMessage, isReady, nikeStoreRoles, storeConfig, isAdmin, storeNumber,
  } = useContext(SimWebContext);

  const {
    addNewRelicAction,
    alertMessage,
    currentFiles,
    getSessionIds,
    getUploadStatus,
    getDelta,
    historicalSession,
    isHistoricalFetch,
    lastSession,
    lastDeltaSessionId,
    paginatedVarianceData,
    rowsPerPage,
    sessionData,
    sessionId,
    setCurrentFiles,
    setTotalCounts,
    setAlertMessage,
    setFetchingHistorical,
    setHistoricalSessions,
    setLastDeltaSessionId,
    setLastSession,
    setPaginatedVarianceData,
    setSessionData,
    setSessionId,
    setVarianceData,
    setUploadStatus,
    totalCounts,
    uploadFile,
    updateSession,
    uploadStatus,
    varianceData,
  } = useContext(PhysicalInventoryUploadContext);

  const {
    isbackClicked, restartTutorial, stepIndex, setBackWasClicked, startTutorial, handleStartTutorial, setTimeOutOnTutorial,
  } = useContext(tutorialContext);

  const title = 'physicalInventoryUpload';
  const { isAuthorized, unauthorizedView: UnauthorizedView } = useAuthorization(title, 'isPIUploadEnabled', 40);

  // const { onGoClick, setRefreshData } = useContext(StockOnHandContext);
  const [isLoading, setIsLoading] = useState(false);
  const [showVarianceLoading, setVarianceLoading] = useState(false);
  const [isUploadModalEnabled, setUploadModalEnabled] = useState(false);
  const [isDragDropDisabled, setDragDropDisabled] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [buttonsDisabled, setButtonsDisabled] = useState(true);
  const [submitted, setIsSubmitted] = useState(false);

  // for tutorial mode data control
  const [tutorialSessionData, setTutorialSessionData] = useState(null);
  const [tutorialLastDeltaSessionId, setTutorialLastDeltaSessionId] = useState(null);
  const [tutorialAlertMessge, setTutorialAlertMessage] = useState(null);
  const [tutorialButtonsDisabled, setTutorialButtonsDisabled] = useState(true);
  const [tutorialVarianceData, setTutorialVarianceData] = useState(null);

  const offsiteType = storeConfig?.offsiteType?.value;
  const startTime = Date.now();

  // log session time after page unrendered
  useEffect(() => () => {
    const duration = Date.now() - startTime;
    newRelicAction('PIUploadSessionDuration', { duration });
  }, []);

  // tutorial mode steps control
  useEffect(() => {
    setPIUploadTutorialSteps(
      isbackClicked, restartTutorial, startTutorial, stepIndex, tutorialVarianceData, setBackWasClicked, setTutorialAlertMessage,
      setTutorialButtonsDisabled, setTutorialLastDeltaSessionId, setTutorialSessionData, setTutorialVarianceData, setTimeOutOnTutorial,
    );
  }, [startTutorial, isbackClicked, restartTutorial, stepIndex, tutorialVarianceData, showVarianceLoading]);

  const getVariance = async (isHistorical) => {
    setVarianceLoading(true);
    setUploadStatus(PIUploadStatus.FETCHING_LAST_VARIANCE.name);
    setVarianceData([]);
    await getDelta(storeId, lastDeltaSessionId, () => {
      setVarianceLoading(false);
      setUploadStatus(PIUploadStatus.SUCCESS.name);
      if (isHistorical || isHistoricalFetch) {
        setUploadStatus(PIUploadStatus.HISTORICAL_REPORT_LOADED.name);
        setFetchingHistorical(false);
        setDragDropDisabled(false);
      }
    });
  };

  const updateSessionStatus = async (piUploadStatus) => {
    const result = await updateSession(storeId, sessionId, piUploadStatus.name,
      () => setAlertMessage(piUploadStatus),
      () => setAlertMessage(PIUploadStatus.STOCK_UPDATE_ERROR),
      () => { setVarianceLoading(false); setIsLoading(false); });
    setUploadStatus(result?.status);
  };

  const onSessionSubmit = async () => {
    setIsLoading(true);
    setVarianceLoading(true);
    setVarianceData([]);
    setButtonsDisabled(true);
    await updateSessionStatus(PIUploadStatus.FILES_SUBMITTED);
  };

  const onSessionCanceled = async () => {
    setIsLoading(true);
    setButtonsDisabled(true);
    await updateSessionStatus(PIUploadStatus.SESSION_CANCELED);
  };

  const onViewClick = async () => {
    setFetchingHistorical(true);
    addNewRelicAction('ViewClicked');
    setAlertMessage(null);
    await getVariance(true);
  };

  const onCancelClick = async () => {
    addNewRelicAction('CancelClicked');
    setAlertMessage(null);
    await onSessionCanceled();
  };

  const onSubmitClick = async () => {
    setIsSubmitted(true);
    addNewRelicAction('SubmitClicked');
    setAlertMessage(null);
    await onSessionSubmit();
  };

  const onFilesUpload = async (acceptedFiles) => {
    setAlertMessage(null);

    const hasDuplicateError = (error, fileName, functionName) => {
      console.error({ message: `onFilesUpload-${functionName}`, error, fileName });
      if (error?.message === 'File aleady uploaded') {
        setUploadStatus(PIUploadStatus.DUPLICATE_FILE_UPLOAD.name);
        setIsLoading(false);
        setVarianceLoading(false);
        return true;
      }

      return false;
    };

    const onFail = (error, errorCode, fileName, status) => {
      if (hasDuplicateError(error, fileName, 'onFail')) return;

      setUploadStatus(status);
      setIsLoading(false);
      setVarianceLoading(false);

      setAlertMessage({
        title: getMessage(PIUploadStatus.FILE_UPLOAD_FAILED.bodegaKey), error, errorCode, fileName, severity: 'error',
      });
    };

    const onError = (error, fileName) => {
      if (hasDuplicateError(error, fileName, 'onError')) return;

      setAlertMessage({
        title: getMessage(PIUploadStatus.FILE_UPLOAD_FAILED.bodegaKey), error: JSON.stringify(error), errorCode: '', fileName, sessionId, severity: 'error',
      });
      setUploadStatus(PIUploadStatus.FILE_UPLOAD_FAILED.name);
      setIsLoading(false);
      setVarianceLoading(false);
    };

    const file = acceptedFiles[0];
    const isDuplicate = currentFiles.filter(candidate => candidate.name === file.name).length > 0;

    if (isDuplicate) {
      setAlertMessage(PIUploadStatus.DUPLICATE_FILE_UPLOADING);
      const files = [...currentFiles];
      files.pop();
      setCurrentFiles(files);
    } else {
      setUploadStatus(PIUploadStatus.FILE_UPLOAD_IN_PROGRESS.name);
      await uploadFile(storeId, file, onFail, onError);
    }
  };

  useEffect(() => {
    setUploadStatus(PIUploadStatus.PAGE_LOAD.name);
    setIsLoading(true);
    setAlertMessage(PIUploadStatus.FETCHING_UPLOAD_STATUS);
  }, []);

  useEffect(() => {
    if (isEmpty(varianceData)) {
      setPaginatedVarianceData([]);
      setTotalCounts(1);
      return;
    }

    if (submitted) {
      toExcel(storeNumber, varianceData);
      setIsSubmitted(false);
      const historicalSessions = [...historicalSession];
      historicalSessions?.splice(0, 0, sessionData);
      setHistoricalSessions(historicalSessions);
    }
  }, [varianceData, isHistoricalFetch]);

  // Initial loads from storeId and sessionId
  useEffect(() => {
    if (storeId && !sessionId) {
      getSessionIds(storeId, (error) => setAlertMessage({ ...PIUploadStatus.ERROR, error }));
    }
    // To handle store's first time
    if (sessionId === 'new') {
      setIsLoading(false);
      setAlertMessage(null);
    }

    if (sessionId && sessionId !== 'new' && storeId) {
      getUploadStatus(storeId, sessionId, true);
      setIsLoading(false);
    }
  }, [storeId, sessionId]);

  // PI Upload LifeCycle
  // DO NOT update the uploadStatus in this useEffect, it will cause side effects
  useEffect(() => {
    if (!uploadStatus) return;

    addNewRelicAction(uploadStatus);

    if (PIUploadStatus.isPageLoad(uploadStatus)) return;

    if (PIUploadStatus.isSessionCanceled(uploadStatus)) {
      setSessionData(lastSession);
      setButtonsDisabled(true);
      setDragDropDisabled(false);
    }

    if (PIUploadStatus.isStockUpdateFailed(uploadStatus)) {
      setAlertMessage(PIUploadStatus[uploadStatus.name]);
      setButtonsDisabled(false);
      setDragDropDisabled(false);
    }

    if (PIUploadStatus.isFileUploadInProgress(uploadStatus)) {
      setSessionData(null);
      setVarianceData([]);
      setButtonsDisabled(true);
      setDragDropDisabled(true);
      setIsLoading(true);
      return;
    }

    if (PIUploadStatus.isFileUploadFailed(uploadStatus)) {
      setSessionData(null);
      setVarianceData([]);
      setButtonsDisabled(true);
      setDragDropDisabled(false);
      setIsLoading(false);
      return;
    }

    if (PIUploadStatus.isFetchingHistoricalReport(uploadStatus)) {
      setButtonsDisabled(true);
      setIsLoading(true);
      return;
    }

    if (PIUploadStatus.isDuplicateFile(uploadStatus)) {
      setAlertMessage(PIUploadStatus[uploadStatus.name]);
      setDragDropDisabled(true);
      setButtonsDisabled(true);
      setSubmitDisabled(true);

      wait(5000)
        .then(async () => {
          await onSessionCanceled();
        })
        .then(async () => {
          const lastSuccessfulSession = historicalSession?.[0];
          setSessionData(null);
          setVarianceLoading(true);
          setSessionId(lastSuccessfulSession?.sessionId);
          setLastSession(lastSuccessfulSession);
          setLastDeltaSessionId(lastSuccessfulSession?.sessionId);
          await getVariance();
        });

      return;
    }

    if (PIUploadStatus.isFileUploadComplete(uploadStatus)) {
      const numOfFiles = sessionData?.files || 0;
      const isDisabled = (numOfFiles === 1 && (offsiteType !== 'active' && offsiteType !== 'csc')) || numOfFiles === 2;
      setButtonsDisabled(false);
      setDragDropDisabled(isDisabled);
      setIsLoading(false);
      setSubmitDisabled(false);
      return;
    }

    if (PIUploadStatus.isFileSubmitted(uploadStatus)) {
      setLastDeltaSessionId(sessionId);
      setVarianceLoading(true);
      getUploadStatus(storeId, sessionId);
      return;
    }

    if (PIUploadStatus.isDeltaCalculationFailed(uploadStatus)) {
      setButtonsDisabled(false);
      setSubmitDisabled(true);
      setDragDropDisabled(false);
      setIsLoading(false);
      return;
    }

    if (PIUploadStatus.isDeltaCalcInProgress(uploadStatus)) {
      setDragDropDisabled(true);
      setSubmitDisabled(true);
      setIsLoading(false);
      return;
    }

    if (PIUploadStatus.isFetchingVarianceReport(uploadStatus)) {
      setVarianceLoading(true);
      setDragDropDisabled(true);
      return;
    }

    if (PIUploadStatus.isSuccessful(uploadStatus)) {
      setDragDropDisabled(false);
    }

    setIsLoading(false);
    setVarianceLoading(false);
  }, [uploadStatus, sessionId, lastSession, isAdmin]);

  // Alert Messaging
  useEffect(() => {
    if (uploadStatus && (alertMessage?.errorCode !== 400 && alertMessage?.errorCode !== 'Unknown')) {
      const status = PIUploadStatus[uploadStatus] ?? PIUploadStatus[uploadStatus.name];
      if (!status) console.error({ message: 'Unknown Upload Status', status: uploadStatus?.name, PIUploadStatus });
      setAlertMessage(status ?? PIUploadStatus.UNKNOWN);
    }
  }, [uploadStatus, alertMessage?.errorCode]);

  useEffect(() => {
    if (PIUploadStatus.isFileCalculated(uploadStatus)) {
      getVariance();
    }
  }, [uploadStatus]);

  const loadingSpinner = (
    <Dialog
      data-testid="pi-upload-dialog-spinner"
      open={isLoading}
      fullScreen={false}
      maxWidth="xs"
      fullWidth
      PaperProps={{
        style: {
          backgroundColor: 'transparent',
          boxShadow: 'none',
        },
      }}
    >
      <div data-testid="loading-physical-inventory-upload-spinner" className="physcial-inventory-upload-loading-spinner">
        <LoadingIndicator />
      </div>
    </Dialog>
  );

  const getAlert = useMemo(() => {
    const message = startTutorial ? tutorialAlertMessge : alertMessage;

    if (!message) return null;

    const {
      title, severity, error, fileName, sessionId, bodegaKey,
    } = { ...message };
    const additionalInfo = error ? { error, fileName, sessionId } : null;
    const isDuplicateFile = (typeof additionalInfo?.error === 'string' || additionalInfo?.error instanceof String) && additionalInfo?.error?.includes('409');

    return (
      <div>
        {
          isDuplicateFile
            ? <Alert severity={severity} pageCode="" apiName={`${title}. ${additionalInfo?.error.split(':')?.[0]}: ${additionalInfo?.fileName}.`} iconSize={28} fontSize={22} />
            : <Alert severity={severity} pageCode="" apiName={bodegaKey ? getMessage(bodegaKey) : title} additionalInfo={additionalInfo} iconSize={28} fontSize={22} />
        }

      </div>
    );
  }, [startTutorial, tutorialAlertMessge, alertMessage]);

  const buttons = useMemo(() => (
    <div className="physical-inventory-upload-button-wrapper">
      <div title={getMessage('physicalInventoryUploadButtonDisabledMessage')}>
        <button
          type="button"
          className="physical-inventory-upload-submit-button"
          data-testid="physical-inventory-upload-submit-button"
          onClick={onSubmitClick}
          disabled={startTutorial ? tutorialButtonsDisabled : submitDisabled || buttonsDisabled}
        >
          {getMessage('submit')}
        </button>
      </div>
      <div title={getMessage('physicalInventoryUploadButtonDisabledMessage')}>
        <button
          type="button"
          className="physical-inventory-upload-cancel-button"
          data-testid="physical-inventory-upload-cancel-button"
          onClick={onCancelClick}
          disabled={startTutorial ? tutorialButtonsDisabled : buttonsDisabled}
        >
          {getMessage('cancel')}
        </button>
      </div>
    </div>
  ), [getMessage, submitDisabled, buttonsDisabled, startTutorial, tutorialButtonsDisabled, onSubmitClick, onCancelClick]);

  const summary = useMemo(() => <PhysicalInventoryUploadSummary data={startTutorial ? tutorialSessionData : sessionData} />, [sessionData, startTutorial, tutorialSessionData]);
  const variance = useMemo(() => (
    <PhysicalInventoryUploadVarianceTable
      title={startTutorial ? tutorialLastDeltaSessionId : lastDeltaSessionId?.toString()}
      paginatedData={startTutorial ? tutorialVarianceData : paginatedVarianceData}
      allData={startTutorial ? tutorialVarianceData : varianceData}
      showLoading={!isLoading && showVarianceLoading}
      viewClick={onViewClick}
      rowsPerPage={startTutorial ? 10 : rowsPerPage}
      totalCounts={startTutorial ? 3 : totalCounts}
    />
  ), [startTutorial, tutorialSessionData, lastDeltaSessionId, showVarianceLoading, varianceData, tutorialVarianceData, paginatedVarianceData, rowsPerPage, onViewClick]);

  if (!isAuthorized || nikeStoreRoles?.maxValue < 40) {
    return UnauthorizedView;
  }

  return isReady ? (
    <div className="physical-inventory-upload-container" data-testid="physicalInventoryUploadReport">
      <div className="header">
        <p className="feature-title" id="physical-inventory-upload-title">
          {getMessage(title)}
          <Tutorial setTutorialState={handleStartTutorial} />
        </p>
      </div>
      {getAlert}
      <div className="physical-inventory-upload-body">
        <div className="physical-inventory-upload-file-summary">
          <div className="left">
            {summary}
          </div>
          <div className="middle">
            <DragDropFileUploader
              numberOfUploads={offsiteType === 'none' || offsiteType === 'inactive' ? 1 : 2}
              fileTypes={['.inv']}
              onFileUpload={onFilesUpload}
              isDisabled={isDragDropDisabled}
              buttons={buttons}
              onCancelClick={onCancelClick}
              tutorial={startTutorial && stepIndex >= 1}
            />
          </div>
        </div>
        {variance}
      </div>
      <Dialog
        open={isUploadModalEnabled}
        fullScreen={false}
        maxWidth="md"
        fullWidth
      >
        <InformationalModal
          message={getMessage('fileUploadError')}
          setIsOpen={setUploadModalEnabled}
        />
      </Dialog>
      {loadingSpinner}
    </div>
  ) : null;
};

export default PhysicalInventoryUpload;
