/* eslint-disable import/prefer-default-export */
import { createStep } from '../tutorial.utils';

export const steps = [
  {
    stepId: 1,
    title: 'inboundVisibilityS1Title',
    content: 'Select a start and end date to search for specific adjustment details between a range of dates',
    target: '[data-testid="date-pickers-container"]',
    placement: 'bottom',
  },
  {
    stepId: 2,
    title: 'inboundVisibilityS2Title',
    content: 'Click on the "Go" button to search the adjustment details for the selected dates',
    target: '[data-testid="date-range-submit-button"]',
    placement: 'bottom-end',
  },
  {
    stepId: 3,
    title: 'stockRoomOrgS2Title',
    content: 'inboundVisibilityS9',
    target: '[data-testid="columnStep"]',
    placement: 'top',
  },
  {
    stepId: 4,
    title: 'inboundVisibilityS8Title',
    content: 'Use the options in the header to search, download, print, or search the adjustment data',
    target: 'div[role^="toolbar"] > div:nth-child(2)',
    placement: 'top',
  },
  {
    stepId: 5,
    title: 'Selecting a single adjustment',
    content: 'Click the checkbox on a given date to select a single or multiple adjustments as needed. You can print or download the adjustments details by clicking the options in the header row',
    target: '[data-testid="MUIDataTableBodyRow-0"]',
    disableOverlayClose: true,

  },
  {
    stepId: 6,
    title: 'inboundVisibilityS10Title',
    content: 'inboundVisibilityS10',
    target: 'thead tr th', // select all checkboxes
    disableOverlayClose: true,
  },
  {
    stepId: 7,
    title: 'Selecting a Single Row',
    target: '.MuiTableBody-root > tr',
    content: 'Click on a single row/date to see additional adjustment details',
    disableOverlayClose: true,
  },
  {
    stepId: 8,
    title: 'Document Type Selector',
    content: 'Select a document type from the dropdown to choose from on of the options', // only for both RUS and GRC
    target: '[data-testid="print-option-block"]',
    disableOverlayClose: true,
    config: true, // only if printOptions is true
  },
  {
    stepId: 9,
    title: 'Select an Address',
    content: 'Select an address to print the adjustment details. You can keep the default address selected or enter a new address in the form', // only for both RUS and GRC
    target: '[data-testid="address-modal-content"]',
    disableOverlayClose: true,
    placement: 'bottom',
    config: true, // only if printOptions is true
    addZindex: true,
  },
];

export const adjustmentsArray = steps.map(step => createStep(step));
export const adjustmentSteps = (translatedSteps, printOptions) => {
  let newStep = [...translatedSteps];

  if (!printOptions) {
    newStep = newStep?.filter(value => value?.config !== true);
  }

  return newStep;
};

/**
 * Util that takes state/utils/configs & handles setting/changing steps in the turorial when clicking through the steps
 * @param {boolean} startTutorial - checks if we're in tutorial mode
 * @param {number} stepIndex - the current step index we are on for the tutorial
 * @param {boolean} restartTutorial - a value used to cancel out any state since the tutorial is no longer active
 * @param {boolean} isbackClicked - a value that handles back clicks within the steps in the tooltip
 * @param {func} setStepIndex - a function that handles setting the step index
 * @param {boolean} isAddressModalOpen - a value that handles open/closing the modal
 * @param {func} setAddressModalOpen - a function that handles setting the modal state
 */
export const setAdjustmentsTutorialSteps = (
  startTutorial, stepIndex, restartTutorial, isbackClicked, setStepIndex, isAddressModalOpen, setAddressModalOpen,
) => {
  const mainCheckbox = document.querySelectorAll('table thead tr input')[0];
  const listOfCheckboxes = document.querySelectorAll('table td input');

  if (startTutorial) {
    if (stepIndex === 3 && isbackClicked) {
      mainCheckbox?.click(); // unselect all
    }
    if (stepIndex === 4) {
      listOfCheckboxes?.[0]?.click(); // select single checkbox
    }
    if (stepIndex === 4 && isbackClicked && mainCheckbox?.checked) {
      mainCheckbox?.click(); // unselect all
      setTimeout(() => { listOfCheckboxes?.[0]?.click(); }, 400); // select first checkbox
    }
    if (stepIndex === 5) {
      if (!isbackClicked) {
        setTimeout(() => { mainCheckbox?.click(); }, 400); // select all
      }
      mainCheckbox?.click(); // unselect all
    }
    if (stepIndex === 6) {
      const row = document.querySelectorAll('table td')[3];
      if (!isbackClicked) mainCheckbox?.click(); // unselect all once again
      setTimeout(() => { row?.click(); }, 400);// select row
    }
    if (stepIndex === 7) {
      if (isbackClicked || isAddressModalOpen) {
        setAddressModalOpen(false);
      }
    }
    if (stepIndex === 8 && !isAddressModalOpen) {
      setTimeout(() => { setAddressModalOpen(true); }, 30);
    }
  }
  if (restartTutorial) {
    if (mainCheckbox?.checked) mainCheckbox.click();
    if (listOfCheckboxes?.[0]?.checked && !mainCheckbox?.checked) listOfCheckboxes?.[0]?.click();
    if (stepIndex === 0 && isAddressModalOpen) setAddressModalOpen(false);
  }
};
