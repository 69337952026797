import React, { useContext, useMemo, useState } from 'react';
import {
  number, func, object, string,
} from 'prop-types';
import {
  Card, DialogContent, Grid, Typography,
} from '@mui/material';
import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { LoadingIndicator } from '../../../../assets/Svgs';

import DialogHeader from '../../../../components/dialogHeader/DialogHeader';
import ShoeboxFragment from '../../../../components/ShoeboxFragment';
import AreaButtons from './AreaButtons';
import GtinTable from './GtinTable';
import ProductInformation from '../../../../components/productDetails/ProductInformation';
import getLocalizedPercent from '../../../../utils/getLocalizedPercent';
import { CPAContext } from '../../../../context/cpa/CPA.provider';
import { SimWebContext } from '../../../../context/SimWeb.provider';

import './ProductCard.css';
import getFormattedPrice from '../../../../utils/getFormattedPrice';

const theme = createTheme(({
  components: {
    MUIDataTableViewCol: {
      styleOverrides: {
        root: {
          padding: '16px 24px 16px 24px !important',
        },
      },
    },
  },
}));

const ProductCard = ({
  setIsOpen, rowCount, scanProductDetailData, scanType, moveNext, movePrevious, currentProduct,
}) => {
  const {
    currencyCode, isOffsiteEnabled, isOffsiteInactive, getMessage, locale, storeConfig,
  } = useContext(SimWebContext);
  const { data, error, loading } = useContext(CPAContext);

  const rfidEnabled = storeConfig?.rfidEnabled?.value;
  const [location, setLocation] = useState('fullStore');
  const [sohTypeSelected, setSohTypeSelected] = useState(rfidEnabled ? 'RFID SOH' : 'SOH');

  const headerRow = useMemo(() => (
    <div className="scan-product-detail-header-row" data-testid="scan-product-detail-header-row">
      <Typography variant="h5" data-testid="scan-product-detail-title">
        {scanProductDetailData?.description}
      </Typography>
      <div className="scan-product-detail-aggregations">
        <p>
          {getMessage('scanned')}
          <span>{scanProductDetailData?.scanned}</span>
        </p>
        <p>
          {getMessage('expected')}
          <span>{scanProductDetailData?.expected}</span>
        </p>
        <p>
          {getMessage('missing')}
          <span>{scanProductDetailData?.missing}</span>
        </p>
        <p>
          {getMessage('extra')}
          <span>{scanProductDetailData?.extra}</span>
        </p>
        <p>
          {getMessage('accuracy')}
          <span>{getLocalizedPercent(locale, scanProductDetailData?.accuracy)}</span>
        </p>
      </div>
    </div>
  ), [scanProductDetailData, getMessage]);

  const areaButtons = useMemo(() => (
    <AreaButtons
      location={location}
      isOffsiteEnabled={isOffsiteEnabled}
      isOffsiteInactive={isOffsiteInactive}
      setLocation={setLocation}
      getMessage={getMessage}
    />
  ), [location, getMessage, isOffsiteEnabled, isOffsiteInactive]);

  const gtinTable = useMemo(() => (
    <GtinTable
      scanAreaData={scanProductDetailData?.inventory?.[location]}
      scanArea={location}
      scanType={scanType}
      rfidEnabled={rfidEnabled}
      sohTypeSelected={sohTypeSelected}
      isOffsiteEnabled={isOffsiteEnabled}
      isOffsiteInactive={isOffsiteInactive}
      getMessage={getMessage}
    />
  ), [scanProductDetailData, location, scanType, sohTypeSelected, getMessage]);

  const productInformation = useMemo(() => (
    <ProductInformation
      product={data?.[0]}
      rfidEnabled={rfidEnabled}
      sohTypeSelected={sohTypeSelected}
      productPrice={getFormattedPrice(data?.[0]?.prices?.currentPrice, locale, currencyCode)}
      locale={locale}
      currencyCode={currencyCode}
      setSohTypeSelected={setSohTypeSelected}
      getMessage={getMessage}
    />
  ), [data, rfidEnabled, sohTypeSelected, locale, currencyCode, getMessage]);

  const cardContent = useMemo(() => {
    if (loading) {
      return (
        <div className="scan-product-detail-loading" data-testid="scan-product-detail-loading">
          <LoadingIndicator width="100px" height="100px" />
        </div>
      );
    }

    if (error || (!data && !loading)) {
      return <div data-testid="productDetail-noResult" className="productDetail-noResult"><ShoeboxFragment label={getMessage('noResults')} /></div>;
    }

    return (
      <DialogContent
        className="scan-product-detail-content flex-row"
        data-testid="scan-product-detail-content"
      >
        <Grid container alignItems="flex-start">
          <Grid item xs>
            {headerRow}
            {scanType === 'FULL_STORE_SCAN' && areaButtons}
            <Typography component="div" className="flex-row" style={{ padding: '0 24px' }}>
              <Grid container direction="row" alignItems="flex-start">
                {productInformation}
                <Grid item xs={8}>
                  {gtinTable}
                </Grid>
              </Grid>
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
    );
  }, [data, error, loading, getMessage, areaButtons, productInformation]);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <div>
          <Card
            className="scan-product-card"
            data-testid="scan-product-card"
            elevation={4}
          >
            <DialogHeader
              loading={false}
              setIsOpen={setIsOpen}
              rowCount={rowCount}
              nodeTitle={currentProduct}
              moveNext={moveNext}
              movePrevious={movePrevious}
              currentProduct={currentProduct}
            />
            {cardContent}
          </Card>
        </div>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

ProductCard.propTypes = {
  setIsOpen: func,
  rowCount: number,
  scanProductDetailData: object,
  scanType: string,
  moveNext: func.isRequired,
  movePrevious: func.isRequired,
  currentProduct: string.isRequired,
};

ProductCard.defaultProps = {
  setIsOpen: null,
  rowCount: null,
  scanProductDetailData: {},
  scanType: '',
};

export default ProductCard;
