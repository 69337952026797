import React, {
  useContext, useEffect, useMemo, useState,
} from 'react';
import { object } from 'prop-types';
import { isEmpty } from 'lodash';
import { Link } from 'react-router-dom';
import { Card, CardContent } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { ErrorOutline } from '@mui/icons-material';
import { Chevron, LoadingIndicator } from '../../../assets/Svgs';
import Tutorial from '../../tutorial/Tutorial';
import { getCountsObject } from '../../../containers/inboundVisibility/visuals/inboundVisibilityVisuals.utils';

import { SimWebContext } from '../../../context/SimWeb.provider';
import { InboundVisibilityContext } from '../../../containers/inboundVisibility/InboundVisibility.provider';
import { tutorialContext } from '../../../context/Tutorial.provider';

import mockedTodayData from '../../tutorial/mockTutorialData/dashboard/inbound-visibility-ship-date-today.json';
import mockedYesterdayData from '../../tutorial/mockTutorialData/dashboard/inbound-visibility-ship-date-yesterday.json';

import './InboundVisibilityWidget.css';

const styles = {
  card: {
    boxShadow: 0,
    backgroundColor: 'white',
    borderRadius: '20px',
  },
  cardTitle: {
    fontSize: '22px',
  },
};

/**
 * Creates a widget with shipments summary for the Inbound Visibility
 * @param {String} classes additional string to cusomize styles
 */
const InboundVisibilityWidget = ({ classes }) => {
  const {
    store: storeName, SimDateTime, storeId, region, getMessage, setInboundSearchDate, country,
  } = useContext(SimWebContext);
  const {
    eddEnabled, error, loading, supportedFilters, getProductShipments,
  } = useContext(InboundVisibilityContext);
  const {
    activateTutorials, stepIndex, handleStartTutorial, dashboardWidget,
    setDashboardWidget,
  } = useContext(tutorialContext);

  const today = activateTutorials ? '2023-01-02' : SimDateTime.now().toISODate();
  const yesterday = activateTutorials ? '2023-01-01' : SimDateTime.now().minus({ days: 1 }).toISODate();
  const [date, setDate] = useState(today);

  const onTutorialStart = () => {
    setDashboardWidget('inboundVisibility');
    handleStartTutorial();
  };

  useEffect(() => {
    let date = today;
    if (stepIndex === 2 && dashboardWidget === 'inboundVisibility') date = yesterday;
    setDate(date);
  }, [activateTutorials, stepIndex]);

  useEffect(() => {
    const compareDate = SimDateTime.toFormat(date, 'yyyy-MM-dd');
    const variables = {
      storeName,
      storeId,
      region,
      textQuery: '',
      count: 100,
      pageNumber: 1,
      filters: [
        {
          name: eddEnabled ? 'edd' : 'shipDate',
          operator: 'EQUALS',
          values: [compareDate],
        },
      ],
      getSummary: false,
      firstPageOnly: true,
    };
    if (!activateTutorials && storeName) getProductShipments(variables);
  }, [date, storeName, country, eddEnabled, activateTutorials]);

  const handleDateChange = (date) => {
    setDate(date);
  };

  const handleWidgetClick = () => {
    setInboundSearchDate(date);
  };

  const inboundWidgetDetails = useMemo(() => {
    if (!activateTutorials && isEmpty(supportedFilters)) {
      return (
        <div className="inbound-visibility-widget-card-error" data-testid="inbound-visibility-widget-empty-data">
          <ErrorOutline color="error" fontSize="large" />
          <br />
          <p>{getMessage('noResult')}</p>
        </div>
      );
    }

    if (!activateTutorials && loading) {
      return (
        <div className="inbound-visibility-widget-card-loading"><LoadingIndicator height="100px" width="100px" fill="#FFFFFF" /></div>
      );
    }

    if (!activateTutorials && error) {
      return (
        <div className="inbound-visibility-widget-card-error" data-testid="inbound-visibility-widget-error">
          <ErrorOutline color="error" fontSize="large" />
          <br />
          <p>{getMessage('fatalError')}</p>
        </div>
      );
    }

    const deliveryType = eddEnabled ? 'edd' : 'shipDate';
    const shipDateValues = supportedFilters.find(item => item.name === deliveryType);
    // eslint-disable-next-line no-nested-ternary
    const deliverValues = activateTutorials ? date === today ? mockedTodayData : mockedYesterdayData : shipDateValues;
    const dataObj = getCountsObject(deliverValues, getMessage);

    return (
      <Link to="/inboundVisibility" style={{ textDecoration: 'none', color: 'inherit' }}>
        <div
          className="inbound-visibility-widget-data"
          data-testid="inbound-visibility-widget-data"
          onClick={() => handleWidgetClick()}
        >
          {
            dataObj.map(item => (
              <React.Fragment key={`inbound-visibility-widget-data-${item.label}`}>
                <div>
                  <p>{`${item.label}:`}</p>
                </div>
                <div
                  className={`inbound-visibility-widget-data-value ${item.name}`}
                  data-testid={`inbound-visibility-widget-data-value-${item.name}`}
                >
                  <p>{item.value}</p>
                </div>
              </React.Fragment>
            ))
          }
        </div>
      </Link>
    );
  }, [supportedFilters, loading, eddEnabled, date, activateTutorials]);

  const inboundVisibilityCardData = (
    <>
      <p className="inbound-visibility-widget-title">{getMessage('inboundVisibility')}</p>
      <div className="inbound-visibility-widget-dates-navigation" data-testid="inbound-visibility-widget-dates-navigation">
        <button
          className="inbound-visibility-widget-previous-date"
          data-testid="inbound-visibility-widget-previous-date"
          type="button"
          onClick={() => handleDateChange(yesterday)}
          disabled={date === yesterday}
          aria-label="Inbound Visibility Previous Date"
        >
          <Chevron fill={date === yesterday ? '#979698' : 'white'} />
        </button>
        <p>{(date === today ? today : yesterday)}</p>
        <button
          className="inbound-visibility-widget-next-date"
          data-testid="inbound-visibility-widget-next-date"
          type="button"
          onClick={() => handleDateChange(today)}
          disabled={date === today}
          aria-label="Inbound Visibility Next Date"
        >
          <Chevron position="right" fill={date === today ? '#979698' : 'white'} />
        </button>
      </div>
      {inboundWidgetDetails}
    </>
  );

  return (
    <Card className={`inbound-visibility-widget-dashboard-card ${classes.card}`} elevation={8} data-testid="inbound-visibility-widget">
      <CardContent>
        {activateTutorials && (!dashboardWidget || dashboardWidget === 'inboundVisibility') && (
          <Tutorial setTutorialState={() => onTutorialStart()} />
        )}
        {inboundVisibilityCardData}
      </CardContent>
    </Card>
  );
};

InboundVisibilityWidget.propTypes = {
  classes: object.isRequired,
};

export default withStyles(styles)(InboundVisibilityWidget);
