/* eslint-disable no-nested-ternary */
import React from 'react';
import {
  get, isArray, isEmpty,
} from 'lodash';
import classNames from 'classnames';
import { Grid } from '@mui/material';
import {
  Review, Time, Close, Checkmark,
} from '../../../assets/Svgs/index';
import InfoTooltip from '../../../components/InfoTooltip';
import TableHeader from '../../../components/TableHeader';
import LoadingAnnimation from '../../../components/loadingAnnimation/LoadingAnnimation';
import CircularProgressWithLabel from '../../../components/circularProgress/CircularProgressWithLabel';
import { getAggregationText, getLocalizedNumber } from '../utils';
import getLocalizedPercent from '../../../utils/getLocalizedPercent';
import getFormattedPrice from '../../../utils/getFormattedPrice';
import { DATE_TIME_SHORT_WITH_APPENDED_ZEROS } from '../../../constants/LocaleFormats';
import {
  scanTypes, scanStatuses, genderLabel, scanTypesLabel, category,
} from '../scanReportConstants';
import { newRelicAction } from '../../../utils/newRelicPageActions';
import { SimDateTime } from '../../../utils/datetime';
import { isEqual } from '../../../utils/isEqual';
import DIVISIONS from '../../../constants/divisions';

/**
 * key used to access data in the array returned when a table row is clicked on
 */
export const tableDataKey = {
  date: 0,
  scanType: 1,
  gender: 2,
  division: 3,
  category: 4,
  scanned: 5,
  expected: 6,
  missing: 7,
  extra: 8,
  accuracy: 9,
  scanReults: 10,
  totalvalueVariance: 11,
  scanDuration: 12,
  dateISO: 13,
  id: 14,
  typeOfscanForFilter: 15,
  lastScanStatus: 16,
  percentComplete: 17,
};

/**
 * A helper function to get the category label
  * @param {string || array} category the name of the product category
  * @param {function} getMessage a function to get translated message
  * @returns {string} the category label
 */

export const getCategoryValue = (categories, getMessage) => {
  const categoryList = categories ? (isArray(categories) ? categories : [categories]) : [];

  if (isEqual(categoryList, category.activeList())) {
    return getMessage('all');
  }

  return (!isEmpty(categoryList) ? `${categoryList[0]}${categoryList?.length > 1 ? ` ${ getMessage('andMore')}` : ''}` : '-');
};

/**
 * A helper function to get the gender label based on the scan type
 * @param {string} scanType the type of scan
 * @param {string || array} categories the name of the product's gender
 * @param {function} getMessage a function to get translated message
 * @returns { string} category label
 */
export const getCategoryLabel = (scanType, category, getMessage) => {
  if (!scanType || !getMessage) return '-';

  let categoryLabel = '-';

  switch (scanType) {
    case scanTypes.CycleCount:
    case scanTypes.Display:
      categoryLabel = getCategoryValue(category, getMessage);
      break;
    default:
      categoryLabel = getMessage('all');
  }

  return categoryLabel;
};

/**
 * A helper function to get the division label of a scan
 * @param {string} scanType the type of scan
 * @param {string} division the name of the product division
 * @param {function} getMessage a function to get translated message
 */
export const getDivisionLabel = (scanType, division, getMessage) => {
  if (!scanType) return '-';

  const divisions = division ? (isArray(division) ? division : [division]) : null;

  let divisionValue = '-';

  switch (scanType) {
    case scanTypes.CycleCount:
    case scanTypes.Display:
      divisionValue = divisions ? divisions.map(item => DIVISIONS.getLabel(item)).join(', ') : '-';
      break;
    case scanTypes.FullStore:
    case scanTypes.SalesFloor:
      divisionValue = getMessage('all');
      break;
    default:
      divisionValue = '-';
  }

  return divisionValue;
};

/**
 * A helper function to get the gender label of a scan
 * @param {array} genderList list of genders to compare as ALL
 * @param {string} gender the name of the product's gender
 * @param {function} getMessage a function to get translated message
 */
const getGenderValue = (scanType, gender, getMessage) => {
  const genders = gender ? (isArray(gender) ? gender : [gender]) : [];
  const others = [genderLabel.OTHER, genderLabel.OTHER.toUpperCase()];
  const useFullList = !scanTypes.isDisplay(scanType) || genders.some(item => others.includes(item));
  const compareList = useFullList ? genderLabel.fullList() : genderLabel.displayScanAllList();

  if (isEqual(genders, compareList)) {
    return getMessage('all');
  }

  return (!isEmpty(genders) ? genders.map(gender => genderLabel[gender.toUpperCase()] || '-').join(', ') : '-');
};

export const getGenderLabel = (scanType, gender, getMessage) => {
  if (!scanType) return '-';

  let genderValue = '-';

  switch (scanType) {
    case scanTypes.CycleCount:
      genderValue = getGenderValue(scanType, gender, getMessage);
      break;
    case scanTypes.Display:
      genderValue = getGenderValue(scanType, gender, getMessage);
      break;
    default:
      genderValue = getMessage('all');
  }

  return genderValue;
};

/**
 * A helper function to get the last scan's status
 * @param {object} scan the scan data
 */
export const getLastScanStatus = (scan) => {
  const statusHistory = get(scan, ['scanStatus', 'statusHistory'], null);
  if (statusHistory) return statusHistory[statusHistory.length - 1].status;
  return '-';
};

/**
 * A helper function to returns the scan aggregations
 * @param {string} locale the language tag
 * @param {array} tableMeta the datatable meta data
 * @param {object} tableKey the table columns index key
 */
export const getScanResultsValues = (locale, tableMeta, tableKey) => {
  const { rowData } = tableMeta;

  if (!rowData || !tableKey) return '-';
  const scanned = getLocalizedNumber(locale, rowData[tableKey.scanned]);
  const expected = getLocalizedNumber(locale, rowData[tableKey.expected]);
  const missing = getLocalizedNumber(locale, rowData[tableKey.missing]);
  const extra = getLocalizedNumber(locale, rowData[tableKey.extra]);
  const accuracy = getLocalizedPercent(locale, rowData[tableKey.accuracy]);

  const values = {
    scanned,
    expected,
    missing,
    extra,
    accuracy,
  };
  const additionalText = getAggregationText(values);

  return (
    <div className="scan-report-summary-counts" data-testid="scan-report-summary-counts-aggregate">
      {additionalText.map(aggregate => (
        <div
          className="scan-report-summary-counts-values"
          data-testid={`scan-report-summary-counts-values-${aggregate.color}`}
          style={{ color: aggregate.color }}
          key={aggregate.color}
        >
          {aggregate.name}
        </div>
      ))}
    </div>
  );
};

/**
 * A helper function to returns the scan aggregations column's label
 * @param {function} getMessage a function to get translated message
 */
export const getScanResultsLabel = (getMessage) => {
  const aggregationLabels = {
    scanned: getMessage('scanned'),
    expected: getMessage('expected'),
    missing: getMessage('missing'),
    extra: getMessage('extra'),
    accuracy: getMessage('accuracy'),
  };

  const additionalText = getAggregationText(aggregationLabels);

  return (
    <div className="scan-report-summary-counts">
      {additionalText.map(aggregate => (
        <div className="scan-report-summary-counts-values" style={{ color: aggregate.color }} key={aggregate.color}>
          {aggregate.name}
        </div>
      ))}
    </div>
  );
};

/**
 * A helper function to get an icon by the scan status
 * @param {string} status the latest scan status of a scan
 * @param {string} label the name of the scan type
 * @param {number} completed the percentage of scanned items processed
 */
export const getScanTypeAndStatusSymbol = (status, label, completed = 0) => {
  if (scanStatuses.isInReview(status)) {
    return (
      <Grid container direction="row">
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Review width="20px" height="20px" fill="#17a2b8" />
          <div style={{ paddingLeft: '5px' }}>
            {label}
          </div>
          <div style={{ paddingLeft: '10px' }}>
            <CircularProgressWithLabel variant="determinate" value={completed} />
          </div>
        </div>
      </Grid>
    );
  }
  if (scanStatuses.isSubmitted(status)) return <Checkmark label={label} width="20px" height="20px" fill="#8AA58F" />;
  if (scanStatuses.isTimedout(status)) return <Time label={label} width="20px" height="20px" fill="#de9423" />;
  if (scanStatuses.isCancelled(status)) return <Close label={label} width="16px" height="16px" fill="#c05850" />;
  if (scanStatuses.isScanning(status)) return <LoadingAnnimation label={label} />;

  return '-';
};

/**
 * A helper function to send a New Relic log if a scan is cancelled or timed out
 * @param {object} scan the scan data
 */
export const newRelicScanStatusUpdate = (scan) => {
  const scanStatus = getLastScanStatus(scan);
  const scanId = get(scan, ['value'], null);
  const startDate = get(scan, ['scanStatus', 'statusHistory', '0', 'date'], null);
  if (scanStatus && scanId && startDate && (scanStatus === 'CANCELLED' || scanStatus === 'TIMED_OUT')) {
    const date = SimDateTime.toDateTime(startDate);
    const today = SimDateTime.now();
    if (SimDateTime.diffInDays(today, date) < 8) {
      const formattedStartDate = SimDateTime.toFormat(startDate, 'MMM D, YYYY');
      newRelicAction('rfid-scan-report-manager-approval',
        {
          action: scanStatus,
          scanIds: scanId,
          startDate: formattedStartDate,
        });
    }
  }
};

/**
 * A helper function to restructure scan data for the scan summary table
 * @param {array} supportedFilters the filter object of the scan data
 * @param {number} scanProgress the percentage of scanned items processed of the latest IN_REVIEW scan
 * @param {function} getMessage a function to get translated message
 */
export const getScanSessionsData = (supportedFilters, scanProgress, getMessage) => {
  const data = [];
  const scanSessions = supportedFilters?.find(_ => _.name === 'sessionId');
  scanSessions?.values?.forEach((scan) => {
    if (scan) {
      newRelicScanStatusUpdate(scan);

      const scanDuration = (scan?.scanStatus?.scanDuration || 0) === 0 ? getMessage('lessThanOneMinute') : `${scan?.scanStatus?.scanDuration} ${getMessage('minutes')}`;
      const scanSummary = {
        id: get(scan, ['value'], null),
        date: get(scan, ['eventDateTime'], '-'),
        dateISO: get(scan, ['eventDateTime'], '-'),
        gender: getGenderLabel(scan.scanType, scan?.scanStatus?.filters?.genderAges, getMessage),
        division: getDivisionLabel(scan.scanType, scan?.scanStatus?.filters?.divisions, getMessage),
        category: getCategoryLabel(scan.scanType, scan?.scanStatus?.filters?.retailCategories, getMessage),
        expected: '-',
        missing: '-',
        extra: '-',
        accuracy: '-',
        scanned: '-',
        typeOfScanForFilter: getMessage(scanTypesLabel[scan.scanType]).toUpperCase(),
        scanDuration,
      };

      // add in aggregation values from display scans into response
      if (scan.aggregations) {
        scan.aggregations.forEach((aggregation) => {
          if (aggregation?.field && aggregation?.value) {
            scanSummary[aggregation.field] = aggregation.value;
          }
        });
      }

      const lastScanStatus = getLastScanStatus(scan);

      if (scanProgress && scanStatuses.isInReview(lastScanStatus)) {
        scanSummary.percentComplete = scanProgress;
      } else {
        const scannedTotalCount = scan?.scanStatus?.scannedTotalCount;
        const scanned = scanSummary.scanned === '-' ? 0 : scanSummary.scanned;
        const percentComplete = Math.round(!scannedTotalCount ? 0 : (scanned / scannedTotalCount) * 100);
        scanSummary.percentComplete = percentComplete;
      }

      scanSummary.typeOfScan = getScanTypeAndStatusSymbol(
        lastScanStatus,
        getMessage(scanTypesLabel[scan.scanType]).toUpperCase(),
        scanProgress,
      );

      data.push(scanSummary);
    }
  });

  return data;
};

/**
 * Rearrange the table headers that are needed for download
 * @param {array} columns list of talble headers
*/
export const buildDownloadHeaders = (columns) => {
  const columnsOrder = [
    'date', 'gender', 'division', 'category', 'scanned', 'expected', 'missing', 'extra', 'accuracy', 'type', 'scanDuration',
  ];

  const headers = [];
  columnsOrder.forEach(item => {
    let header = columns.find(_ => _.name === item);
    if (item === 'type') {
      header = columns.find(_ => _.name === 'typeOfScanForFilter');
    }
    if (header) headers.push(header);
  });
  return headers;
};

/**
 * Rearrange the data to match the headers order
 * @param {array} data table data for download
 * @param {object} dataKey data index mapping
 */
export const buildDownloadData = (data) => {
  const toDownload = [];

  data?.forEach(item => {
    const rowData = item?.data;
    const row = {
      data: [
        SimDateTime.toLocaleString(rowData[tableDataKey.date], DATE_TIME_SHORT_WITH_APPENDED_ZEROS),
        rowData[tableDataKey.gender],
        rowData[tableDataKey.division],
        rowData[tableDataKey.category],
        rowData[tableDataKey.scanned],
        rowData[tableDataKey.expected],
        rowData[tableDataKey.missing],
        rowData[tableDataKey.extra],
        rowData[tableDataKey.accuracy],
        rowData[tableDataKey.typeOfscanForFilter],
        rowData[tableDataKey.totalvalueVariance],
        rowData[tableDataKey.scanDuration],
      ],
    };
    toDownload.push(row);
  });
  return toDownload;
};

/**
 * Generates a tooltip component for scan status.
 * @param {Function} getMessage - A function to retrieve localized messages.
 * @returns {JSX.Element} - The tooltip component.
 */
export const scanStatusToolTip = (getMessage) => (
  <div style={{ padding: '10px' }}>
    <div style={{ marginBottom: '5px' }}>
      <Review fill="#17a2b8" width="20px" height="20px" />
      <span>{`  ${getMessage(':inReview')}`}</span>
    </div>
    <div style={{ marginBottom: '5px' }}>
      <Checkmark width="20px" height="20px" fill="#8AA58F" />
      <span>{`  ${getMessage(':submitted')}`}</span>
    </div>
    <div style={{ marginBottom: '5px' }}>
      <Time width="20px" height="20px" fill="#F2A127" />
      <span>{`  ${getMessage(':timedOut')}`}</span>
    </div>
    <div style={{ marginBottom: '5px' }}>
      <Close width="16px" height="16px" fill="#c05850" />
      <span>{`  ${getMessage(':cancelled')}`}</span>
    </div>
    <div style={{ marginBottom: '5px', marginLeft: '10px' }}>
      <LoadingAnnimation label={getMessage(':scanning')} />
    </div>
  </div>
);

/**
 * Returns the class names for the scan report summary table header.
 * @param {Object} columnMeta - The metadata of the column.
 * @param {boolean} disabled - Indicates if the header is disabled.
 * @returns {string} - The class names for the table header.
 */
export const getClassNames = (columnMeta, disabled) => classNames(
  'scan-report-summary-table-header',
  { 'scan-report-summary-table-header-disabled': disabled },
  { 'scan-report-summary-table-header-selected': columnMeta.sortDirection !== 'none' },
);

/**
 * Returns a string representation of the date range.
 * @param {Object} date - The date range object.
 * @returns {string} - The string representation of the date range.
 */
export const getScanDateRangeString = (date) => {
  const { start, end } = date;

  return `${SimDateTime.toLocaleString(start, SimDateTime.DATETIME_SHORT)} - ${end && SimDateTime.toLocaleString(end, SimDateTime.DATETIME_SHORT)}`;
};

/**
 * Returns an array of column configurations for the scan report summary table.
 * @param {string} locale - The locale used for formatting dates and numbers.
 * @param {string} currencyCode - The currency code used in the store.
 * @param {Function} getMessage - A function to retrieve localized messages.
 * @param {Function} getClassNames - A function to retrieve CSS class names.
 * @returns {Array} An array of column configurations.
 */
export const getColumns = (locale, currencyCode, getMessage, getClassNames) => [
  {
    name: 'date',
    label: getMessage('date'),
    options: {
      filter: false,
    },
  },
  {
    name: 'scanType',
    options: {
      filter: false,
      customBodyRender: (value, tableMeta) => {
        const lastScanStatus = tableMeta?.rowData?.[tableDataKey.lastScanStatus];
        const percentComplete = tableMeta?.rowData?.[tableDataKey.percentComplete];
        const label = getMessage(scanTypesLabel?.[value] ?? value).toUpperCase();
        return getScanTypeAndStatusSymbol(lastScanStatus, label, percentComplete);
      },
      customHeadRender: () => (
        <th
          key="typeOfScan"
          style={{
            borderBottom: '1px solid #e0e0e0',
            position: 'sticky',
            zIndex: 100,
            top: 0,
            background: 'white',
            fontWeight: '400',
          }}
        >
          <span
            className="customHeaderWithTooltip"
            style={{
              display: 'flex',
              fontFamily: 'Helvetica',
              fontSize: '0.875rem',
              margin: '0 0 0 10px',
              paddingTop: '16.5px',
              lineHeight: '1.43',
              color: 'black',
            }}
          >
            <p style={{ paddingTop: '2px', paddingRight: '5px' }}>{getMessage('type')}</p>
            <InfoTooltip
              content={scanStatusToolTip(getMessage)}
              placement="bottom"
            />
          </span>
        </th>
      ),
    },
  },
  {
    name: 'gender',
    label: getMessage('gender'),
    options: {
      filter: false,
      customBodyRender: (value, tableMeta) => (getGenderLabel(tableMeta?.rowData?.[tableDataKey.scanType], value, getMessage)),
    },
  },
  {
    name: 'division',
    label: getMessage('division'),
    options: {
      filter: false,
      customBodyRender: (value, tableMeta) => (getDivisionLabel(tableMeta?.rowData?.[tableDataKey.scanType], value, getMessage)),
    },
  },
  {
    name: 'category',
    label: getMessage('category'),
    options: {
      filter: false,
      customBodyRender: (value, tableMeta) => (getCategoryLabel(tableMeta?.rowData?.[tableDataKey.scanType], value, getMessage)),
    },
  },
  {
    name: 'scanned',
    label: getMessage('scanned'),
    options: {
      customBodyRender: value => getLocalizedNumber(locale, value),
      display: 'excluded',
      filter: false,
    },
  },
  {
    name: 'expected',
    label: getMessage('expected'),
    options: {
      customBodyRender: value => getLocalizedNumber(locale, value),
      display: 'excluded',
      filter: false,
    },
  },
  {
    name: 'missing',
    label: getMessage('missing'),
    options: {
      customBodyRender: value => getLocalizedNumber(locale, value),
      display: 'excluded',
      filter: false,
    },
  },
  {
    name: 'extra',
    label: getMessage('extra'),
    options: {
      customBodyRender: value => getLocalizedNumber(locale, value),
      display: 'excluded',
      filter: false,
    },
  },
  {
    name: 'accuracy',
    label: getMessage('accuracy'),
    options: {
      customBodyRender: value => getLocalizedPercent(locale, value),
      display: 'excluded',
      filter: false,
    },
  },
  {
    name: 'scanResults',
    label: ' ', // Empty string to prevent the label from being displayed in the table
    options: {
      customBodyRender: (value, tableMeta) => getScanResultsValues(locale, tableMeta, tableDataKey),
      filter: false,
      customHeadRender: (columnMeta, handleToggleColumn) => {
        const { label } = columnMeta;
        return (
          <TableHeader
            columnMeta={columnMeta}
            handleToggleColumn={handleToggleColumn}
            disabled
            getClasses={getClassNames}
            dataTestId="scan-report-summary-header-scan-results"
            key={label}
            secondaryInfo={getScanResultsLabel(getMessage)}
          />
        );
      },
    },
  },
  {
    name: 'totalValueVariance',
    label: getMessage('totalValueVariance'),
    options: {
      customBodyRender: value => {
        let className = '';
        if (value < 0) {
          className = 'scan-total-value-variance-value-negative';
        } else if (value > 0) {
          className = 'scan-total-value-variance-value-positive';
        }

        return (<span className={className} style={{ maxWidth: '100px' }}>{getFormattedPrice(value, locale, currencyCode)}</span>);
      },
    },
  },
  {
    name: 'scanDuration',
    label: getMessage('scanDuration'),
    options: {
      filter: false,
      customBodyRender: value => (value === 0 ? getMessage('lessThanOneMinute') : `${value} ${getMessage('minutes')}`),
    },
  },
  {
    name: 'dateISO',
    options: {
      customBodyRender: (value, tableMeta) => tableMeta.rowData[tableDataKey.date],
      display: 'excluded',
      filter: false,
    },
  },
  {
    name: 'id',
    options: {
      display: 'excluded',
      filter: false,
    },
  },
  {
    // used to filter the table since typeOfScan is a component
    name: 'typeOfScanForFilter',
    label: getMessage('type'),
    options: {
      customBodyRender: (value, tableMeta) => tableMeta.rowData[tableDataKey.scanType],
      display: 'excluded',
      filterType: 'multiselect',
    },
  },
  {
    name: 'lastScanStatus',
    options: {
      display: 'excluded',
      filter: false,
    },
  },
  {
    name: 'percentComplete',
    options: {
      display: 'excluded',
      filter: false,
    },
  },
];
