/* eslint-disable no-unused-expressions */
import React, {
  createContext, useContext, useEffect, useState,
} from 'react';
import writeXlsxFile from 'write-excel-file';
import { node, object } from 'prop-types';
import { SimWebContext } from '../../context/SimWeb.provider';
import { baseUrl, routerUrls, endpoints } from '../../axios/endpoints';
import { generalAxiosRequest } from '../../axios/axiosFunctions';
import { SimDateTime } from '../../utils/datetime';

export const StockOnHandContext = createContext({});

/**
  * Stock on Hand Provider provides:
  *     - Columns and rows for both summary and details
  *     - Table Options - includes custom download to xlsx
  *     - Data, loading state, and error state
  *
  * @param {node} children - data that we iterate on and create data visuals
  * @param {object} mockedValue - mock values for testing
 */

const StockOnHandProvider = ({ children, mockedValue }) => {
  const { Provider } = StockOnHandContext;
  const {
    storeId, getMessage, isPrintingEnabled, storeConfig,
  } = useContext(SimWebContext);

  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const rfidEnabled = storeConfig?.rfidEnabled?.value;

  // Used to label and denote which report the base view components are rendering
  const id = 'stockOnHand';

  /**
   * Fetches both the summary and details of the stock on hand report
   */
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const url = `${baseUrl(routerUrls.SIMWEB_BFF)}${endpoints.STOCK_ON_HAND.url}?storeId=${storeId}`;
        const response = await generalAxiosRequest('GET', url, endpoints.STOCK_ON_HAND);
        setData(response);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    if (storeId) {
      fetchData();
    }
  }, [storeId]);

  /**
   * Builds the header rows for xlsx download
   * @param {*} columns - visible columns of the table
   * @returns an array of objects with value of the header and fontWeight
   */
  const buildDownloadHeaders = (columns) => {
    const headerRow = [];
    columns?.map(column => {
      headerRow.push({ value: column.label.toUpperCase(), fontWeight: 'bold' });
    });
    return headerRow;
  };

  /**
   * Builds the rows for xlsx download
   * @param {*} rows - visible rows of the table (should only change if filters are applied)
   * @returns an array of objects with value of the row and type
   */
  const buildDownloadData = (rows) => {
    const toDownload = [];
    rows?.map(item => {
      const row = item.data.map((value, idx) => {
        // The first 7 columns are strings, the last 3 are numbers (SOH, RFID Stock, and Variance)
        if (idx >= 7) {
          return { value: parseInt(value, 10) ?? 0, type: Number };
        }
        return { value: value?.toString() ?? '', type: String };
      });
      toDownload.push(row);
    });
    return toDownload;
  };

  /**
   * Options for the table
   */
  const tableOptions = {
    selectableRows: 'none',
    selectableRowsHeader: false,
    rowsPerPage: 100,
    rowsPerPageOptions: [10, 25, 50, 100],
    print: isPrintingEnabled,
    responsive: 'standard',
    textLabels: {
      body: {
        noMatch: getMessage('noData'),
      },
      toolbar: {
        downloadCsv: 'Download XLS',
      },
    },
    sortOrder: {
      name: 'fiscalSoh',
      direction: 'desc',
    },
    downloadOptions: {
      filename: `Stock-on-Hand-${SimDateTime.toUtcISO()}.csv`,
      filterOptions: {
        useDisplayedColumnsOnly: true,
        useDisplayedRowsOnly: true,
      },
    },
    onDownload: (buildHead, buildBody, newColumns, newData) => {
      const headerRow = buildDownloadHeaders(newColumns);
      const dataRows = buildDownloadData(newData, rfidEnabled);
      try {
        writeXlsxFile([headerRow, ...dataRows], { fileName: `Stock-on-Hand-${SimDateTime.toUtcISO()}.xlsx` });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
      return false; // cancels default datatable download logic
    },
  };

  /**
   * Columns for the table.
   */
  const columns = [{
    name: 'bin',
    label: getMessage('bin'),
    options: {
      filter: false,
    },
  }, {
    name: 'styleColor',
    label: getMessage('style-color'),
    options: {
      filterType: 'textField',
    },
  }, {
    name: 'class',
    label: getMessage('class'),
    options: {
      filterType: 'multiselect',
    },
  }, {
    name: 'division',
    label: getMessage('division'),
    options: {
      filterType: 'multiselect',
    },
  }, {
    name: 'description',
    label: getMessage('description'),
    options: {
      filterType: 'textField',
    },
  }, {
    name: 'gtin',
    label: getMessage('upc'),
  }, {
    name: 'size',
    label: getMessage('size'),
  }, {
    name: 'soh',
    label: getMessage('stockOnHand'),
    options: {
      filter: false,
    },
  },
  ];

  // Add rfid stock and the difference between fiscal and rfid stock if rfid is enabled
  if (rfidEnabled) {
    columns.push({
      name: 'rfidStock',
      label: getMessage('rfidStock'),
      options: {
        filter: false,
      },
    }, {
      name: 'difference',
      label: getMessage('difference'),
      options: {
        filter: false,
      },
    });
  }

  return (
    <Provider
      value={mockedValue ?? {
        id,
        data,
        error,
        loading,
        columns,
        tableOptions,
      }}
    >
      {children}
    </Provider>
  );
};

StockOnHandProvider.defaultProps = {
  children: {},
  mockedValue: null,
};

StockOnHandProvider.propTypes = {
  children: node,
  mockedValue: object,
};

export default StockOnHandProvider;
