/* eslint-disable consistent-return */
/* eslint-disable max-len */
import React, {
  createContext, useContext, useEffect, useState, useRef,
} from 'react';
import { node, object } from 'prop-types';

import { routerUrls, endpoints, baseUrl } from '../../axios/endpoints';
import { checkForFiscalDocumentLink, performFiscalPrint } from '../../axios/fiscalAxiosFunctions';
import { isOutsideDateRange } from '../../utils/isOutsideDateRange';
import { setAdjustmentsTutorialSteps } from '../../components/tutorial/stepConstants/adjustments';
import { tutorialContext } from '../../context/Tutorial.provider';

import { SimWebContext } from '../../context/SimWeb.provider';
import { generalAxiosRequest } from '../../axios/axiosFunctions';
// WIP: send HTML file to service
import { createAndSendHtml } from '../../axios/challanCreation.axios';

export const AdjustmentsContext = createContext({});

export const greeceDefaultAddress = {
  address1: '27 ΧΛΜ ΠΑΛΑΙΑΣ ΕΘΝΙΚΗΣ ΟΔΟΥ ΑΘΙ',
  address2: 'ΙΙ',
  city: 'ΑΤΤΙΚΗ',
  postalCode: '19200',
};

const AdjustmentsProvider = ({ children, mockedValue }) => {
  const { Provider } = AdjustmentsContext;
  const {
    store, currencyCode, getMessage, isIOS, country, SimDateTime, region, isAdmin,
  } = useContext(SimWebContext);

  const {
    stepIndex, setStepIndex, startTutorial, isbackClicked, restartTutorial,
  } = useContext(tutorialContext);

  const [startDate, setStartDate] = useState(SimDateTime.startOfDay());
  const [endDate, setEndDate] = useState(SimDateTime.endOfDay());
  const [detailsData, setDetailsData] = useState([]);
  const [summaryData, setSummaryData] = useState({});
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [address1, setAddress1] = useState(greeceDefaultAddress.address1);
  const [address2, setAddress2] = useState(greeceDefaultAddress.address2);
  const [city, setCity] = useState(greeceDefaultAddress.city);
  const [postalCode, setPostalCode] = useState(greeceDefaultAddress.postalCode);
  const [defaultSelected, setDefaultSelected] = useState(true);

  const [adjustmentId, setAdjustmentId] = useState('');
  const [dialogData, setDialogData] = useState([]);
  const [isAddressModalOpen, setAddressModalOpen] = useState(false);
  const [isErrorOpen, setErrorOpen] = useState(false);
  const challanRef = useRef();

  useEffect(() => {
    const fetchAdjustments = async () => {
      setIsLoading(true);
      setError(null);
      setDetailsData([]);
      setSummaryData({});
      try {
        const url = `${baseUrl(routerUrls.SIMWEB_BFF)}${endpoints.ADJUSTMENTS.url}`
        + `?store=${store}`
        + `&region=${region}`
        + `&creationDateAfterEq=${SimDateTime.toUtcISO(startDate)}`
        + `&creationDateBeforeEq=${SimDateTime.toUtcISO(endDate)}`;

        const response = await generalAxiosRequest('GET', url, endpoints.ADJUSTMENTS);
        setDetailsData(response.details);
        setSummaryData(response.summary);
      } catch (error) {
        setError(error);
      }
      setIsLoading(false);
    };

    if (startDate && endDate && store) {
      fetchAdjustments();
    }
  }, [startDate, endDate, store]);

  /**
   * Tutorial Magic!
   * useEffect is here rather than in the tutorial provider because of extra logic needed for this specific page.
   */
  useEffect(() => {
    setAdjustmentsTutorialSteps(startTutorial, stepIndex, restartTutorial, isbackClicked, setStepIndex, isAddressModalOpen, setAddressModalOpen);
  }, [stepIndex, startTutorial, isbackClicked, restartTutorial, isAddressModalOpen]);

  const printOptionsByCountry = {
    RUS: ['adjustmentSummary', 'adjustmentDoc'],
    GRC: ['adjustmentSummary', 'dispatchNote'],
  };

  const maxDate = SimDateTime.endOfDay();
  const isOutsideRange = date => isOutsideDateRange(date, null, maxDate);

  const setGreekAddress = (isChecked) => {
    setDefaultSelected(isChecked);
    // If re-selecting to use the default address, reset it to default values.
    if (isChecked) {
      setAddress1(greeceDefaultAddress.address1);
      setAddress2(greeceDefaultAddress.addreessLine2);
      setCity(greeceDefaultAddress.city);
      setPostalCode(greeceDefaultAddress.postalCode);
    }
  };

  const handleFiscalPrint = async (adjustmentId) => {
    const payload = {
      request: {
        adjustmentId,
        address: {
          address1: !defaultSelected ? address1 : '',
          address2: !defaultSelected ? address2 : '',
          city: !defaultSelected ? city : '',
          postalCode: !defaultSelected ? postalCode : '',
        },
      },
    };
    try {
      await performFiscalPrint(endpoints.ADJUSTMENTS_FISCAL_PRINT, payload);
    } catch (error) {
      setErrorOpen(true);
      setDialogData(error.message);
    }
    setAddressModalOpen(false);
  };

  const handleGreecePrint = async (adjustmentIds) => {
    if (adjustmentIds?.length > 1) {
      setErrorOpen(true);
      return setDialogData(getMessage('errorOneAdjustmentOnly'));
    }

    setAdjustmentId(adjustmentIds[0]);

    const validTypes = ['MISMATE', 'STAFF_DRESS', 'OBSOLETE'];
    const object = detailsData?.find(item => item?.adjustmentId === adjustmentIds[0]);

    if (!validTypes.includes(object?.type)) {
      setErrorOpen(true);
      return setDialogData(getMessage('errorInvalidAdjustmentTypes'));
    }
    try {
      const document = await checkForFiscalDocumentLink(adjustmentIds[0]);
      if (document) {
        const payload = {
          request: {
            adjustmentId: adjustmentIds[0],
          },
        };
        return await performFiscalPrint(endpoints.ADJUSTMENTS_FISCAL_PRINT, payload);
      }
      setAddressModalOpen(true);
    } catch (error) {
      setErrorOpen(true);
      setDialogData(error.message);
    }
  };

  const handlePrintingRequest = async (selectedItems, selectedPrintType) => {
    const adjustmentIds = await selectedItems?.map(item => item.adjustmentId);
    if (selectedPrintType === 'dispatchNote') {
      return handleGreecePrint(adjustmentIds);
    }

    return adjustmentIds?.map(id => {
      const payload = { request: { adjustmentId: id } };
      performFiscalPrint(endpoints.ADJUSTMENTS_FISCAL_PRINT, payload);
    });
  };

  // WIP: TODO: send html file to service in here
  const handleSendingChallan = async () => {
    createAndSendHtml(challanRef);
  };

  const refreshData = (options) => {
    const { startDate: currentStartDate, endDate: currentEndDate } = options;
    setStartDate(SimDateTime.toDateTime(currentStartDate));
    setEndDate(SimDateTime.toDateTime(currentEndDate));
  };

  const onGoClick = (options) => {
    refreshData(options);
  };

  return (
    <Provider
      value={mockedValue ?? {
        isAddressModalOpen,
        isIOS,
        isErrorOpen,
        isLoading,
        adjustmentId,
        details: detailsData,
        dialogData,
        summary: summaryData,
        address1,
        address2,
        city,
        currencyCode,
        defaultSelected,
        error,
        printOptions: printOptionsByCountry[country] || null,
        postalCode,
        getMessage,
        startDate,
        endDate,
        handleFiscalPrint,
        handleGreecePrint,
        handlePrintingRequest,
        isOutsideRange,
        onGoClick,
        setGreekAddress,
        setAddress1,
        setAddress2,
        setPostalCode,
        setCity,
        setAddressModalOpen,
        setDefaultSelected,
        setDetailsData,
        setDialogData,
        handleSendingChallan,
        setErrorOpen,
        challanRef,
        isAdmin,
      }}
    >
      {children}
    </Provider>
  );
};

AdjustmentsProvider.defaultProps = {
  children: {},
  mockedValue: null,
};

AdjustmentsProvider.propTypes = {
  children: node,
  mockedValue: object,
};

export default AdjustmentsProvider;
