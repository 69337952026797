import React, { useContext } from 'react';

import Tutorial from '../components/tutorial/Tutorial';
import { tutorialContext } from '../context/Tutorial.provider';

const useTutorial = (name) => {
  const {
    activateTutorials,
    stepIndex,
    setStepIndex,
    startTutorial,
    dashboardWidget,
    handleStartTutorial,
    setDashboardWidget,
    isTutorialEnabled,
  } = useContext(tutorialContext);

  const isWidget = !dashboardWidget || dashboardWidget === name;

  const onTutorialStart = (widgetName) => {
    if (isWidget) setDashboardWidget(widgetName);
    handleStartTutorial();
  };

  const getTutorial = (disabled = false) => (
    isTutorialEnabled && (
      <Tutorial setTutorialState={() => onTutorialStart(name)} disabled={disabled} />
    )
  );

  const TutorialComponent = () => (
    (activateTutorials && isWidget) && (
      <Tutorial
        setTutorialState={() => onTutorialStart(name)}
      />
    ));

  return {
    activateTutorials,
    stepIndex,
    setStepIndex,
    startTutorial,
    isWidget,
    isTutorialEnabled,
    handleStartTutorial,
    getTutorial,
    Tutorial: TutorialComponent,
  };
};

export default useTutorial;
