/* eslint-disable no-use-before-define */
const fs = require('fs');
const axios = require('axios');
// const FormData = require('form-data');

// WIP current set up for sending HTML Challan file
// eslint-disable-next-line import/prefer-default-export
export const createAndSendHtml = async (reference) => {
  // Step 1: Create HTML content
  // const htmlContent = `
  // <!DOCTYPE html>
  // <html lang="en">
  // <head>
  //     <meta charset="UTF-8">
  //     <meta name="viewport" content="width=device-width, initial-scale=1.0">
  //     <title>Sample HTML</title>
  // </head>
  // <body>
  //     <h1>Hello, World!</h1>
  //     <p>This is a sample HTML file.</p>
  // </body>
  // </html>
  // `;

  // Step 2: Write HTML content to a file
  const filePath = 'sample.html';
  fs.writeFileSync(filePath, reference);
  // fs.writeFileSync(filePath, htmlChallan);

  // Step 3: Send the HTML file to the service via an API call
  const form = new FormData();
  form.append('file', fs.createReadStream(filePath));

  try {
    const response = await axios.post('service-api-url', form, {
      headers: {
        ...form.getHeaders(),
        'Content-Type': 'text/html',
      },
    });

    // Check the response status
    if (response.status === 200) {
      // eslint-disable-next-line no-console
      console.log('File sent successfully.');
    } else {
      // eslint-disable-next-line no-console
      console.log(`Failed to send file. Status code: ${response.status}, Response: ${response.data}`);
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(`Error sending file: ${error.message}`);
  }
};
