/* eslint-disable max-len */
import React, {
  createContext, useContext, useEffect, useState, useMemo,
} from 'react';
import { node, object } from 'prop-types';
import { isEmpty } from 'lodash';
import { binned, topSeller } from './styleManagementConstants';
import { getErrorMessages, sortDefaultBySOH, getStyleManagementFilters } from './StyleManagement.utils';
import { getVisualSummaryData } from '../../utils/getVisualSummaryData';
import { getStyleManagementData } from './styleManagement.axios';
import { SimWebContext } from '../../context/SimWeb.provider';
import { newRelicAction } from '../../utils/newRelicPageActions';

export const StyleManagementDataContext = createContext({});

const StyleManagementDataProvider = ({ children, mockedContext }) => {
  const { Provider } = StyleManagementDataContext;
  const {
    store, storeId, storeInfo, storeConfig, region, isOffsiteEnabled, isOffsiteInactive,
  } = useContext(SimWebContext);

  const { address, isEMEA } = { ...storeInfo };
  const merchGroup = address?.iso2Country;
  const rfidEnabled = storeConfig?.rfidEnabled?.value;
  const eddEnabled = storeConfig?.isEDDEnabled?.value;

  const [buttonFilters, setButtonFilters] = useState([binned]);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [rows, setRows] = useState([]);
  const [sohList, setSohList] = useState([]);
  const [rfidSohList, setRfidSohList] = useState([]);
  const [tableFilters, setTableFilters] = useState([]);
  const [tableFilteredData, setTableFilteredData] = useState([]);
  const [refetchData, setRefetchData] = useState(false);

  const fetchData = async (storeId, store, region, buttonFilters, merchGroup, isEMEA, eddEnabled, tableFilters) => {
    const loadTimeStart = Date.now();
    let filters;
    setLoading(true);
    setData([]);
    setTableFilteredData([]);
    setError(null);

    try {
      filters = getStyleManagementFilters(buttonFilters, tableFilters);
      const payload = {
        storeId,
        store,
        region,
        merchGroup,
        isEMEA,
        eddEnabled,
        isOffsiteEnabled,
        rfidEnabled,
        styleManagementFilters: filters,
      };
      const { products, errors } = await getStyleManagementData(payload);
      setError(getErrorMessages(errors));
      setData(products);
      setTableFilteredData(products);
      setLoading(false);
    } catch (error) {
      setError(getErrorMessages(error));
      setLoading(false);
    } finally {
      const loadTimeEnd = Date.now();
      const filterNames = filters.map(filter => filter.name);
      const loadTime = loadTimeEnd - loadTimeStart;
      newRelicAction('styleManagementPageLoadDuration', { duration: loadTime, filters: filterNames });
    }
  };

  const summaryData = useMemo(() => {
    if (loading || isEmpty(data)) {
      return {
        data: [],
        summary: [],
      };
    }

    const summary = getVisualSummaryData(data, ['Apparel', 'Footwear', 'Equipment'], ['genderAge', 'retailCategory']);

    return ({
      data,
      summary,
    });
  },
  [tableFilteredData, loading]);

  useEffect(() => {
    if (storeId && store) fetchData(storeId, store, region, buttonFilters, merchGroup, isEMEA, eddEnabled, tableFilters);
  }, [storeId, store, buttonFilters, rfidEnabled, eddEnabled, merchGroup]);

  useEffect(() => {
    if (refetchData && buttonFilters.includes(topSeller)) {
      fetchData(storeId, store, region, buttonFilters, merchGroup, isEMEA, eddEnabled, tableFilters);
    }
    setRefetchData(false);
  }, [refetchData]);

  useEffect(() => {
    if (summaryData?.data) {
      const rows = summaryData?.data;
      const sortedRows = sortDefaultBySOH(rows);
      setRows(sortedRows);
    }
  }, [summaryData?.data, tableFilters, setTableFilteredData, rfidEnabled, isOffsiteEnabled]);

  // gets the highest SOH and RFIDSOH number and generates a list of integers
  // between 0 to that number for the custom filters.
  useEffect(() => {
    if (rows?.length) {
      const flattenedSoh = [...new Set(rows.flatMap(row => (row.soh === '-' ? 0 : +row.soh)))];
      const maxSoh = Math.max(...flattenedSoh);
      setSohList(Array.from({ length: maxSoh + 1 }, (_, i) => i));

      const flattenedRfidSoh = [...new Set(rows.flatMap(row => (row.rfidSohSum === '-' ? 0 : +row.rfidSohSum)))];
      const maxRfidSoh = Math.max(...flattenedRfidSoh);
      setRfidSohList(Array.from({ length: maxRfidSoh + 1 }, (_, i) => i));
    }
  }, [rows?.length]);

  const refreshClick = () => {
    fetchData(storeId, store, region, buttonFilters, merchGroup, isEMEA);
  };

  return (
    <Provider
      value={mockedContext ?? {
        buttonFilters,
        error,
        loading,
        isOffsiteEnabled,
        isOffsiteInactive,
        merchGroup,
        rfidEnabled,
        rfidSohList,
        rows,
        sohList,
        storeId,
        summary: { loading, data: summaryData?.summary },
        tableFilters,
        tableFilteredData,
        refetch: refreshClick,
        setRows,
        setButtonFilters,
        setTableFilters,
        setTableFilteredData,
        setRefetchData,
      }}
    >
      {children}
    </Provider>
  );
};

StyleManagementDataProvider.defaultProps = {
  children: {},
  mockedContext: null,
};

StyleManagementDataProvider.propTypes = {
  children: node,
  mockedContext: object,
};

export default StyleManagementDataProvider;
