/* eslint-disable max-len */
/* eslint-disable object-curly-newline */
import React from 'react';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import DIVISIONS from '../../constants/divisions';

import {
  BinnedBox, UnbinnedBox, Missing, ClothesOnHanger, BackInTime, Flame, PriceTag, Shipping, NewStyle,
} from '../../assets/Svgs/index';

/**
* Filter definitions for platform filtering of data.
* These will be applied when the user selects a filter button.
*/
const genderAge = {
  name: 'genderAge',
  operator: 'EQUALS',
  values: [
    'Mens', 'Womens', 'Kids', 'Other',
    // potentially deprecated
    'Boys Big Kid', 'Girls Big Kid', 'Adult Unisex', 'Boys Little Kid', 'Boys Infant/Toddler', 'Unisex Big Kid', 'Girls Little Kid', 'Unisex Little Kid', 'Girls Infant/Toddler', 'Unisex Infant/Toddler',
  ],
};

const category = {
  name: 'retailCategory',
  operator: 'EQUALS',
  values: [
    'Action Sports', 'American Football', 'Athletic Training', 'Baseball/Softball', 'Basketball', 'Causes', 'Cole Haan', 'Collegiate', 'Converse', 'Digital Sport', 'Football/Soccer', 'Golf', 'Hurley', 'Jordan', 'Lacrosse', 'Livestrong', 'Mens Training', 'Nike SB', 'Other', 'Performance Swim', 'Rugby', 'Running', 'Sportswear', 'Tennis', 'Umbro', 'Volleyball', 'Walking', 'Womens Training', 'Young Athletes', 'Bras',
    // potentially deprecated
    'Nike Sportswear', 'Jordan Brand', 'Nike Basketball', 'Nike Golf', 'Baseball Other Field Sports', 'All Other',
  ],
};

const fiscalSohNotZero = {
  name: 'fiscalSoh',
  operator: 'NOTEQUAL',
  values: ['0'],
};

export const styleManagementFilters = {
  category,
  genderAge,
  fiscalSohNotZero,
};

const fixtureAssignment = {
  name: 'fixtured',
  operator: 'EQUALS',
  values: ['true'],
};

export const binned = {
  name: 'binned',
  operator: 'EQUALS',
  values: ['true'],
};

const unbinned = {
  name: 'binned',
  operator: 'EQUALS',
  values: ['false'],
};

const clearance = {
  name: 'clearance',
  operator: 'EQUALS',
  values: ['true'],
};

const missingStyles = {
  name: 'missingStyles',
  operator: 'EQUALS',
  values: ['true'],
};

const agedInventory = {
  name: 'agedInventory',
  operator: 'EQUALS',
  values: ['true'],
};

export const topSeller = {
  name: 'topSeller',
  operator: 'EQUALS',
  values: ['true'],
};

const inboundUnits = {
  name: 'inboundUnits',
  operator: 'EQUALS',
  values: ['true'],
};

const newStyle = {
  name: 'newItem',
  operator: 'EQUALS',
  values: ['true'],
};

export const inboundDivisionsFilter = {
  name: 'division',
  operator: 'EQUALS',
  values: DIVISIONS.allFilterValues,
};

/**
* Updates the selected filter button based on the filter type.
*
* @param {Object} filterButtons - The array of filter buttons.
* @param {string} filterType - The filter type.
* @returns {Object} - The updated object of filter buttons.
*/
export const customButtonSelected = (filterButtons, filterType) => {
  if (filterButtons.length === 0 || !filterType) return filterButtons;

  const selectedButton = filterButtons?.[filterType];

  if (selectedButton?.opposite && selectedButton.selected === false) {
    selectedButton.opposite.forEach(oppositeBtnName => {
      const oppositeBtn = filterButtons?.[oppositeBtnName];
      if (oppositeBtn?.selected === true) oppositeBtn.selected = false;
    });
  }

  if (selectedButton) {
    selectedButton.selected = !selectedButton?.selected || false;
  }

  return filterButtons;
};

/**
 * Constants for filter button types in style management.
 * Leaving is admin for now in case it gets used in the future.
 */
// eslint-disable-next-line no-unused-vars
export const filterButtonTypes = () => ({
  /**
   * Aged Inventory filter button.
   * @type {Object}
   * @property {string} i18nKey - The internationalization key.
   * @property {React.Component} svg - The SVG component for the button icon.
   * @property {string} name - The name of the button.
   * @property {boolean} enabled - Indicates if the button is enabled.
   * @property {Function} filter - The filter function associated with the button.
   */
  agedInventory: { name: 'agedInventory', i18nKey: 'agedInventory', i18nKeyInfo: 'agedInventoryInfo', selected: false, svg: BackInTime, enabled: true, filter: agedInventory, opposite: ['newStyle', 'topSeller'], customButtonSelected },

  /**
   * Binned Styles filter button.
   * @type {Object}
   * @property {string} i18nKey - The internationalization key.
   * @property {boolean} selected - Indicates if the button is selected.
   * @property {React.Component} svg - The SVG component for the button icon.
   * @property {string} name - The name of the button.
   * @property {boolean} enabled - Indicates if the button is enabled.
   * @property {Function} filter - The filter function associated with the button.
   */
  binned: { name: 'binned', i18nKey: 'binnedStyles', i18nKeyInfo: 'unbinnedStylesInfo', sort: 1, selected: true, svg: BinnedBox, enabled: true, filter: binned, opposite: ['unbinned'], customButtonSelected },

  /**
   * Clearance filter button.
   * @type {Object}
   * @property {string} i18nKey - The internationalization key.
   * @property {React.Component} svg - The SVG component for the button icon.
   * @property {string} name - The name of the button.
   * @property {boolean} enabled - Indicates if the button is enabled.
   * @property {string} config - Name of the configuration value to check if the button is enabled.
   * @property {Function} filter - The filter function associated with the button.
   */
  clearance: { name: 'clearance', i18nKey: 'clearance', i18nKeyInfo: 'clearanceInfo', sort: 3, selected: false, svg: PriceTag, enabled: false, config: 'isClearanceEnabled', filter: clearance },

  /**
   * Fixture Assignment filter button.
   * @type {Object}
   * @property {string} i18nKey - The internationalization key.
   * @property {React.Component} svg - The SVG component for the button icon.
   * @property {string} name - The name of the button.
   * @property {boolean} enbaled - Indicates if the button is enabled.
   * @property {Function} filter - The filter function associated with the button.
   */
  fixtureAssignment: { name: 'fixtureAssignment', i18nKey: 'fixtureAssignment', i18nKeyInfo: '', selected: false, svg: ClothesOnHanger, enabled: false, filter: fixtureAssignment },

  /**
   * Hot Sellers filter button.
   * @type {Object}
   * @property {string} i18nKey - The internationalization key.
   * @property {React.Component} svg - The SVG component for the button icon.
   * @property {string} name - The name of the button.
   * @property {boolean} enabled - Indicates if the button is enabled.
   * @property {string} config - Name of the configuration value to check if the button is enabled.
   * @property {Function} filter - The filter function associated with the button.
   */
  topSeller: { name: 'topSeller', i18nKey: 'topSeller', i18nKeyInfo: 'topSellerInfo', selected: false, svg: Flame, enabled: false, config: 'isTopSellersEnabled', filter: topSeller, opposite: ['agedInventory'], customButtonSelected },

  /**
   * Inbound Units filter button.
   * @type {Object}
   * @property {string} i18nKey - The internationalization key.
   * @property {React.Component} svg - The SVG component for the button icon.
   * @property {string} name - The name of the button.
   * @property {boolean} enabled - Indicates if the button is enabled.
   * @property {Function} filter - The filter function associated with the button.
   */
  inboundUnits: { name: 'inboundUnits', i18nKey: 'inboundUnits', i18nKeyInfo: 'inboundUnitsInfo', sort: 5, selected: false, svg: Shipping, enabled: true, filter: inboundUnits },

  /**
   * Last Week Markdowns filter button.
   * @type {Object}
   * @property {string} i18nKey - The internationalization key.
   * @property {null} svg - The SVG component for the button icon.
   * @property {string} name - The name of the button.
   * @property {boolean} enabled - Indicates if the button is enabled.
   */
  lastWeekMarkdowns: { name: 'lastWeekMarkdowns', i18nKey: 'lastWeekMarkdowns', i18nKeyInfo: '', selected: false, svg: null, enabled: false },

  /**
   * Low Inventory filter button.
   * @type {Object}
   * @property {string} i18nKey - The internationalization key.
   * @property {React.Component} svg - The SVG component for the button icon.
   * @property {string} name - The name of the button.
   * @property {boolean} enabled - Indicates if the button is enabled.
   */
  lowInventory: { name: 'lowInventory', i18nKey: 'lowInventory', i18nKeyInfo: '', selected: false, svg: ClothesOnHanger, enabled: false },

  /**
   * Product Not Found filter button.
   * @type {Object}
   * @property {string} i18nKey - The internationalization key.
   * @property {null} svg - The SVG component for the button icon.
   * @property {string} name - The name of the button.
   * @property {boolean} enabled - Indicates if the button is enabled.
   */
  productNotFound: { name: 'productNotFound', i18nKey: 'productNotFound', i18nKeyInfo: '', selected: false, svg: null, enabled: false },

  /**
   * This Week Markdowns filter button.
   * @type {Object}
   * @property {string} i18nKey - The internationalization key.
   * @property {null} svg - The SVG component for the button icon.
   * @property {string} name - The name of the button.
   * @property {boolean} enabled - Indicates if the button is enabled.
   */
  thisWeekMarkdown: { name: 'thisWeekMarkdowns', i18nKey: 'thisWeekMarkdowns', i18nKeyInfo: '', selected: false, svg: null, enabled: false },

  /**
   * Missing Styles filter button.
   * @type {Object}
   * @property {string} i18nKey - The internationalization key.
   * @property {React.Component} svg - The SVG component for the button icon.
   * @property {string} name - The name of the button.
   * @property {boolean} enabled - Indicates if the button is enabled.
   * @property {Function} filter - The filter function associated with the button.
   */
  missingStyles: { name: 'missingStyles', i18nKey: 'missingStyles', i18nKeyInfo: 'missingStylesInfo', sort: 4, selected: false, svg: Missing, enabled: true, filter: missingStyles },

  /**
   * New Style filter button.
   * @type {Object}
   * @property {string} i18nKey - The internationalization key.
   * @property {React.Component} svg - The SVG component for the button icon.
   * @property {string} name - The name of the button.
   * @property {boolean} enabled - Indicates if the button is enabled.
   * @property {Function} filter - The filter function associated with the button.
   */
  newStyle: { name: 'newStyle', i18nKey: 'newStyle', i18nKeyInfo: 'newStyleInfo', selected: false, svg: NewStyle, enabled: true, filter: newStyle, opposite: ['agedInventory'], customButtonSelected },

  /**
   * Nike.com filter button.
   * @type {Object}
   * @property {string} i18nKey - The internationalization key.
   * @property {React.Component} svg - The SVG component for the button icon.
   * @property {string} name - The name of the button.
   * @property {boolean} enabled - Indicates if the button is enabled.
   */
  dotCom: { name: 'dotCom', i18nKey: 'Nike.com', selected: false, svg: ShoppingCartOutlinedIcon, enabled: false },

  /**
   * Unbinned Styles filter button.
   * @type {Object}
   * @property {string} i18nKey - The internationalization key.
   * @property {React.Component} svg - The SVG component for the button icon.
   * @property {string} name - The name of the button.
   * @property {boolean} enabled - Indicates if the button is enabled.
   * @property {Function} filter - The filter function associated with the button.
   */
  unbinned: { name: 'unbinned', i18nKey: 'unbinnedStyles', i18nKeyInfo: 'unbinnedStylesInfo', sort: 2, selected: false, svg: UnbinnedBox, enabled: true, filter: unbinned, opposite: ['binned'], customButtonSelected },

  /**
   * Get an array of all filter buttons.
   * @returns {Array} - An array of filter buttons.
   */
  buttons() {
    const { length } = Object.values(this);
    return Object.values(this).sort((a, b) => (a?.sort || length) - (b?.sort || length));
  },

  /**
   * Get an array of enabled filter buttons.
   * @returns {Array} - An array of enabled filter buttons.
   */
  enabledButtons() { return this.buttons().filter((button) => button.enabled); },

  /**
   * Returns an object containing the enabled filter button types.
   * @returns {Object} An object containing the enabled filter button types.
   */
  enabled() {
    const enabledButtons = this.buttons()
      .reduce((acc, value) => {
        if (value.enabled) acc[value.name] = value;
        return acc;
      }, {});

    return enabledButtons;
  },

  /**
   * Get the SVG icon component for a given button.
   * @param {string} value - The value to match against the i18nKey or name of the button.
   * @returns {React.Component|null} - The SVG icon component or null if not found.
   */
  getSvgIcon(value) {
    const { svg, name } = this.buttons().find((button) => button?.i18nKey === value || button?.name === value) || {};
    return svg ? React.createElement(svg, { extraClasses: 'buttonIcon', key: `${name}-icon` }) : null;
  },
});
