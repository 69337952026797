/* eslint-disable no-underscore-dangle */
import React, { useEffect, useRef, useState } from 'react';
import {
  array,
  string,
  bool,
  func,
  number,
  oneOfType,
} from 'prop-types';
import classNames from 'classnames';
import {
  Chart, ArcElement, Tooltip, Legend,
} from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

import { createGradient, updateData } from '../visualSummaries.utils';
import { getCenterTextString } from './donutChart.utils';

/**
 * Custom positioner
 * @function Tooltip.positioners.myCustomPositioner
 * @param elements items in the tooltip
 * @returns {TooltipPosition} the tooltip position
 */
// eslint-disable-next-line func-names
Tooltip.positioners.toolTipPosition = function (items) {
  const pos = Tooltip.positioners.average(items);

  // Happens when nothing is found
  if (pos === false) {
    return false;
  }

  const chart = this._chart;

  return {
    x: pos.x,
    y: chart.chartArea.bottom,
  };
};

/**
 * Creates a donut chart with the custom params passed in
 * https://www.chartjs.org/docs/master/charts/doughnut.html
 * @param {String} centerText custom text to display in the center of the graph
 * @param {String} chartType uniquely identifies how to handle customizing the rendered chart data
 * @param {Function} customToolTip optional function to modify the tooltip data on the data slices
 * @param {Array} data the values to set the data, labels, and color values
 * @param {Boolean} displayCenterText option to show a value in the center of the graph
 * @param {String} extraClass option to pass an additional class to modify the comp
 * @param {Boolean} isDashboardWidget bool to indicate whether the widget is a dashboard widget
 * @param {Boolean} legendEnabled gives you the option to hide/show the legend
 * @param {Boolean} stopAnimation a boolean indicating whether to stop the animation or not
 * @param {String} title the value that sets the chart title
 * @param {String} width to modify the chart width
 */
const DonutChart = ({
  centerTextLabel,
  centerValue,
  chartType,
  customToolTip,
  data,
  displayCenterText,
  extraClass,
  isDashboardWidget,
  legendEnabled,
  stopAnimation,
  title,
  width,
}) => {
  Chart.register(ArcElement, Tooltip, Legend);

  const [chartData, setChartData] = useState({
    datasets: [],
  });

  const chartRef = useRef(null);
  let dataArray = [];
  let colorArray = [];
  let labelArray = [];

  if (data?.length && !dataArray.length) {
    ({ dataArray, colorArray, labelArray } = updateData(data));
  }

  const customizedToolTip = {
    label: (tooltipItem) => customToolTip(tooltipItem, data),
  };

  const options = {
    ...(stopAnimation ? { animation: false } : null),
    displayColors: true,
    layout: {
      padding: {
        bottom: 25,
      },
    },
    plugins: {
      legend: {
        display: legendEnabled,
        responsive: true,
        position: 'bottom',
        labels: {
          boxWidth: 15,
          font: {
            size: 14,
          },
          color: 'white',
        },
      },
      tooltip: {
        position: customToolTip ? 'nearest' : 'toolTipPosition',
        titleFont: {
          size: 14,
        },
        bodyFont: {
          size: 14,
        },
        enabled: !!data.length,
        mode: 'dataset',
        callbacks: (customToolTip !== null && typeof customToolTip === 'function' && data.length) ? customizedToolTip : {},
      },

    },
  };

  const donutLabel = displayCenterText && {
    id: 'donutLabel',
    afterDatasetsDraw: (chart) => {
      const { ctx, data } = chart;

      const centerX = chart?.getDatasetMeta(0)?.data[0]?.x;
      const centerY = chart?.getDatasetMeta(0)?.data[0]?.y;

      const isEmptyChart = data?.labels?.[0] === 'N/A';
      const centerTextString = centerValue !== ''
        ? centerValue
        : getCenterTextString(data?.datasets?.[0]?.data, chartType, centerTextLabel, isEmptyChart);

      ctx.save();
      ctx.font = `bold ${isDashboardWidget ? '12px' : '14px'} Arial`;
      ctx.fillStyle = 'white';
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.fillText(centerTextString, centerX, centerY);
    },
  };

  useEffect(() => {
    const chart = chartRef.current;

    if (!chart) {
      return;
    }

    const newChartData = {
      maintainAspectRatio: false,
      responsive: true,
      labels: labelArray,
      datasets: [
        {
          data: dataArray,
          backgroundColor: createGradient(chart.ctx, colorArray),
          borderColor: createGradient(chart.ctx, colorArray),
          hoverBackgroundColor: createGradient(chart.ctx, colorArray),
        },
      ],
    };

    if (!chartData.datasets.length && data.length) {
      setChartData(newChartData);
    }

    if (!dataArray.length && !data.length && chartType === 'compliance' && !chartData.datasets.length) {
      dataArray.push('100');
      labelArray.push('Represented');
    }

    if (chartType !== 'compliance' && !dataArray.length && !data.length && !chartData.datasets.length) {
      dataArray.push('100');
      labelArray.push('N/A');

      newChartData.datasets[0].data = dataArray;
      newChartData.labels = labelArray;
      newChartData.datasets[0].backgroundColor = '#434244';
      newChartData.datasets[0].borderColor = '#434244';
      newChartData.datasets[0].hoverBackgroundColor = '#434244';

      setChartData(newChartData);
    }

    if (dataArray.length && dataArray?.length !== chartData?.datasets?.[0]?.data.length) {
      setChartData(newChartData);
    }
  }, [dataArray, chartData, colorArray, chartType, labelArray]);

  return (
    <div className={classNames('DonutChart_container', extraClass)}>
      <div style={{ textAlign: 'center', width }}>
        <p>{title}</p>
        <Doughnut
          data={chartData}
          options={options}
          plugins={[donutLabel]}
          ref={chartRef}
          id={title}
          data-testid={data?.length ? 'DonutChart_container' : 'DonutChart_container-noData'}
        />
      </div>
    </div>
  );
};

DonutChart.propTypes = {
  centerTextLabel: string,
  centerValue: oneOfType([
    string,
    number,
  ]),
  chartType: string,
  customToolTip: func,
  data: array,
  displayCenterText: bool,
  extraClass: string,
  isDashboardWidget: bool,
  legendEnabled: bool,
  stopAnimation: bool,
  title: string,
  width: string,
};

DonutChart.defaultProps = {
  centerTextLabel: '',
  centerValue: '',
  customToolTip: null,
  chartType: '',
  data: [],
  displayCenterText: false,
  extraClass: '',
  isDashboardWidget: false,
  legendEnabled: false,
  stopAnimation: false,
  title: '',
  width: null,
};

export default DonutChart;
