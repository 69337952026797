import React, { useState, useEffect } from 'react';
import {
  func, string, arrayOf, shape,
  bool,
} from 'prop-types';
import './Options.css';

const NewOptions = ({
  disabled, optionsLabel, options, onOptionChange,
}) => {
  const [selectedOption, setSelectedOption] = useState(options[0] && options[0].value);

  useEffect(() => {
    if (onOptionChange && typeof onOptionChange === 'function') {
      onOptionChange(selectedOption);
    }
  }, [selectedOption, onOptionChange]);

  const opts = [];

  if (options && options.length) {
    // map options to jsx for render
    options.forEach(opt => opts.push(
      <option value={opt.value} key={opt.value}>
        {opt.label}
      </option>,
    ));
  }

  return (
    <div className="options-container">
      <div className="label">
        {optionsLabel}
      </div>
      <svg>
        <polygon points="0,0 4,7 8,0" />
      </svg>
      <select onChange={e => setSelectedOption(e.target.value)} value={selectedOption} disabled={disabled}>
        {opts}
      </select>
    </div>
  );
};

NewOptions.propTypes = {
  disabled: bool,
  onOptionChange: func,
  options: arrayOf(shape({
    label: string,
    value: string,
  })),
  optionsLabel: string,
};

NewOptions.defaultProps = {
  disabled: false,
  onOptionChange: null,
  options: [],
  optionsLabel: 'general.options',
};

export default NewOptions;
