import React, { useContext } from 'react';
import Dialog from '@mui/material/Dialog';
import DateRangeWithOptions from '../../components/date-range/DateRangeWithOptions';
import ErrorAlert from '../../components/alerts/Error';
import { LoadingIndicator } from '../../assets/Svgs/index';
import Tutorial from '../../components/tutorial/Tutorial';

import OOSTrackingTable from './OOSTrackingTable';
import OOSAuditDialogDetails from './OOSAuditDialogDetails';
import { oosAuditContext } from './OOSAudits.provider';
import { tutorialContext } from '../../context/Tutorial.provider';

import './OOSAuditsPage.css';

export const OOSAuditsPage = () => {
  const {
    data,
    dialogData,
    endDate,
    error,
    isOpen,
    loading,
    startDate,
    getMessage,
    isOutsideRange,
    onGoClick,
    setDialogData,
    setOpen,
  } = useContext(oosAuditContext);

  const { handleStartTutorial } = useContext(tutorialContext);

  const handleModalClose = (setOpen) => {
    setOpen(false);
  };

  const dataForRender = data || { totalAverage: '...', audits: [] };

  return (
    <div className="oos-audits-page flex-column" data-testid="oos-audits-page">
      <div className="header flex-row">
        <div className="title-block flex-column">
          <div className="feature-title">
            {getMessage('oosAudits')}
          </div>
          <div className="feature-subtitle">
            {getMessage('totalAverage')}
            {`: ${dataForRender.totalAverage}`}
            {'%'}
          </div>
        </div>
        <div className="page-controls flex-row">
          <div className="date-picker-block" data-testid="oosAuditPageDateRangeWithOptions">
            <DateRangeWithOptions
              start={startDate.toISO()}
              end={endDate.toISO()}
              onClick={onGoClick}
              disabled={loading}
              isOutsideRange={isOutsideRange}
            />
          </div>
        </div>
      </div>
      {error && (
        <ErrorAlert
          errorObject={error}
          apiName="OOS Audits API"
          pageCode=""
        />
      )}
      <Tutorial setTutorialState={handleStartTutorial} />
      <div className="details">
        {!loading && (
          <OOSTrackingTable
            dataForRender={dataForRender}
            setDialogData={setDialogData}
            setOpen={setOpen}
            loading={loading}
          />
        )}
        <Dialog
          open={isOpen}
          onClose={() => handleModalClose(setOpen)}
          maxWidth="md"
          scroll="paper"
          fullWidth
        >
          <OOSAuditDialogDetails
            dialogData={dialogData}
          />
        </Dialog>
        {loading && <LoadingIndicator />}
      </div>
    </div>
  );
};

export default OOSAuditsPage;
