import React from 'react';
import { func } from 'prop-types';
import IconButton from '@mui/material/IconButton';
import withStyles from '@mui/styles/withStyles';
import Tooltip from '@mui/material/Tooltip';
import PrintIcon from '@mui/icons-material/Print';

const ChallanPrintToolTip = withStyles({
  tooltip: {
    backgroundColor: '#292929',
    color: '#DEDEDE',
    accentColor: '#292929',
    fontWeight: 500,
    fontSize: '12px',
    border: '1px solid black',
    padding: '10px',
  },
})(Tooltip);

/**
 * Custom datatable toolbar used on the Challan page,
 * allows the user to create a challan with selected rows in the table
 * @param {func} printChallan - the function to print challans with selected rows when clicked
 */
const ChallanPrintToolBar = ({
  printChallan,
}) => (
  <div className="challan-print-toolbar-container" data-testid="challan-print-toolbar-container">
    <ChallanPrintToolTip className="scan-progress-tool-tip" data-testid="scan-progress-tooltip" title="Print Challan" placement="top">
      <IconButton
        className="challan-print-button"
        data-testid="challan-print-button"
        onClick={printChallan}
        size="large"
      >
        <PrintIcon />
      </IconButton>
    </ChallanPrintToolTip>
  </div>
);

ChallanPrintToolBar.propTypes = {
  printChallan: func.isRequired,
};

export default ChallanPrintToolBar;
