import React from 'react';
import { number, string } from 'prop-types';

const Swoosh = ({ width, height, fill }) => (
  <svg width={width} height={height} style={{ marginLeft: 35 }} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M114 0L30.6301 34.6722C23.6889 37.5595 17.8497 39 13.1442 39C7.84966 39 3.99276 37.1683 1.62415 33.5111C-1.44744 28.7983 -0.104804 21.2044 5.1644 13.1946C8.29299 8.51282 12.2702 4.21605 16.1461 0.105556C15.2341 1.55851 7.18468 14.691 15.9878 20.8753C17.7294 22.1172 20.2057 22.7257 23.2519 22.7257C25.6965 22.7257 28.5021 22.3345 31.5864 21.5459L114 0Z"
      fill={fill}
    />
  </svg>
);

Swoosh.propTypes = {
  height: number,
  width: number,
  fill: string,
};

Swoosh.defaultProps = {
  height: 60,
  width: 150,
  fill: '#9b9657',
};

export default Swoosh;
