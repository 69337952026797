import React from 'react';
import { Dialog } from '@mui/material';
/**
 * use as a dialog container for modal pops with contect, such as Product Cards
 * to the BaseViewPage
 * @param {string} id page id
 * @param {object} content dialog modal content, such as a product card
 * @param {boolean} isOpen variable to deterimine if the modal is visibile
 * @param {func} setIsOpen function to set state of the isOpen variable
 */
export const baseDialogWrapper = ({
  id, content, isOpen, setIsOpen,
}) => (
  <div data-testid={`${id}-dialog-container`}>
    <Dialog
      open={isOpen}
      onClose={() => setIsOpen(false)}
      data-testid={`${id}-dialog`}
      PaperProps={{
        style: {
          height: 'auto',
          minHeight: '65vh',
          maxHeight: '90vh',
          minWidth: '80%',
          maxWidth: '80%',
        },
      }}
    >
      {content}
    </Dialog>
  </div>
);

/**
 * use as the default MuiTableTeme, this object can be extended and then passed in
 * to the BaseViewPage
 */
export const baseMuiTheme = {
  components: {
    MuiTableRow: {
      styleOverrides: {
        root: {
          cursor: 'pointer',
          height: '10px',
          width: '10px',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: '16px 10px',
        },
      },
    },
    MUIDataTableHeadCell: {
      styleOverrides: {
        fixedHeader: {
          padding: '10px',
        },
      },
    },
  },
};

/**
 * use as the default MuiTableOptions, this object can be extended and then passed in
 * to the BaseViewPage
 * @param {function} getMessage translations helper func
 */
export const baseDetailsTableOptions = (getMessage) => ({
  selectableRows: 'none',
  selectableRowsHeader: false,
  rowsPerPage: 100,
  rowsPerPageOptions: [15, 25, 50, 100, 1000],
  print: true,
  responsive: 'scrollFullHeight', // DO NOT change this allows to print all rows
  download: false,
  filter: true,
  textLabels: {
    body: {
      noMatch: getMessage('noData'),
    },
  },
  sortOrder: {
    name: 'productCode',
    direction: 'desc',
  },
  fixedHeader: true,
});
