/* eslint-disable react/jsx-no-literals */
import React from 'react';
import { object } from 'prop-types';
import Grid from '@mui/material/Unstable_Grid2';
import Swoosh from '../../assets/Svgs/Swoosh';
import ProductTable from './ChallanTable';

const styles = {
  textAlignRight: {
    border: '1px solid black',
    borderCollapse: 'collapse',
    textAlign: 'right',
    paddingRight: '4px',
  },
  boxContainer: {
    border: '1px solid black',
    margin: '0 4px 0 0',
  },
  header: {
    margin: '10px 0',
    fontWeight: 'bold',
  },
  pStyles: {
    margin: '0',
  },
  pCondensedStyles: {
    margin: '1.5px 0',
  },
  pSpacedStyles: {
    margin: '0 0 6px 0',
  },
  boxContainerWithMargin: {
    border: '1px solid black',
    minWidth: '100px',
    padding: '6px 0 6px 6px',
    minHeight: '80px',
    maxWidth: '400px',
    margin: '7px 0 0 0',
  },
};

const mockData = {
  products: [
    {
      styleColor: 'DV3853-004',
      hsnCode: '6404190',
      desc: 'Air Zoom Pegasus 40',
      sizes: [
        {
          prlD: 1,
          size: 9,
          uom: 'PR',
          qtyOrdered: 1,
          qtyShipped: 4,
          unitPrice: '1,003.19',
          SGST: '9.00%',
          CGST: '9.00%',
          IGST: '0.00%',
        }, {
          prlD: 1,
          size: 10,
          uom: 'PR',
          qtyOrdered: 1,
          qtyShipped: 1,
          unitPrice: '1,920.90',
          SGST: '9.00%',
          CGST: '9.00%',
          IGST: '0.00%',
        },
      ],
      subtotals: {
        qtyOrdered: 1,
        qtyShipped: 1,
        netUnitPrice: '2,123.19',
        SGST: '191.09',
        CGST: '191.09',
        IGST: '-',
        total: '2,505.37',
      },
    },
    {
      styleColor: 'FN7487-133',
      hsnCode: '64039990',
      desc: 'Nike Air Max 1',
      sizes: [
        {
          prlD: 1,
          size: 7,
          uom: 'PR',
          qtyOrdered: 3,
          qtyShipped: 0,
          unitPrice: '3,243.73',
          SGST: '9.00%',
          CGST: '9.00%',
          IGST: '0.00%',
        }, {
          prlD: 1,
          size: 8,
          uom: 'PR',
          qtyOrdered: 7,
          qtyShipped: 5,
          unitPrice: '1,000.00',
          SGST: '9.00%',
          CGST: '9.00%',
          IGST: '0.00%',
        },
        {
          prlD: 1,
          size: 9,
          uom: 'PR',
          qtyOrdered: 1,
          qtyShipped: 3,
          unitPrice: '2,231.02',
          SGST: '9.00%',
          CGST: '9.00%',
          IGST: '0.00%',
        },
      ],
      subtotals: {
        qtyOrdered: 1,
        qtyShipped: 1,
        netUnitPrice: '2,372.39',
        SGST: '291.13',
        CGST: '291.13',
        IGST: '',
        total: '3,816.99',
      },
    },
  ],
  totals: {
    taxableValue: '5357.92',
    totalQuantity: '2',
    SGST: '482.22',
    CGST: '482.2',
    IGST: '0.00',
    total: '6,322.36',
  },
  gstNumber: '29AABC96K123',
  cinNumber: 'U369339KA2004RTC034515',
  panNumber: 'AABC9612k',
  invoice: '8000017625',
  invoiceDate: '08.07.2024',
  division: 'Footwear Division',
  customerNumber: 'C000000000',
  orderNumber: 5089993,
  deliveryNumber: 24566435,
  poNumber: 467675342,
  orderedBy: 'awakan',
  orderedDate: '07/03/2024',
  placeOfSupply: 'KARNATAKA',
  gstin: 'GST',
  terms: 'NIL',
  poDate: '',
  dueDate: '',
  currency: 'INR',
  stateCode: '',
  modeOfTransport: '0001',
  lrNumber: '',
  weight: 2,
  numberOfCartons: 1,
  cost: 'ONE HUNDRED THIRTY-FOUR THOUSAND SIX HUNDRED SIXTY-THREE Rupees THIRTEEN Paise',
  dateShipped: '16.01.2025',
  shippedFrom: 'Nike India Private Limited c/o DHL Supply Chain India Pvt Ltd Mayasandra Village 298/1, Anekal - Attibele Road 562107 BENGALURU INDIA KARNATAKA',
  orderType: 'Futures Order',
  sapReferenceNumber: '7277602566',
  pageNumber: 1,
  totaPages: 1,
};
const ChallanForm = ({ reference }) => {
  // const [data, setData] = useState({});

  // useEffect(() => {
  //   const fetchChallanData = async () => {
  //     setData(mockData);
  //   };

  //   if (mockData && isEmpty(data)) fetchChallanData();

  // }, [data]);

  const SpanningTable = (
    <div>
      <ProductTable data={mockData} />
      <Grid item sm={6} style={{ padding: 0 }}>
        <table aria-label="taxable data table" style={{ width: '100%' }}>
          <tr>
            <th style={styles.textAlignRight}>Taxable Value</th>
            <th style={styles.textAlignRight}>Total Quantity</th>
            <th style={styles.textAlignRight}>SGST</th>
            <th style={styles.textAlignRight}>CGST</th>
            <th style={styles.textAlignRight}>IGST</th>
            <th style={styles.textAlignRight}>Total Value</th>
          </tr>
          <tr>
            <td style={styles.textAlignRight}>{mockData?.totals?.taxableValue}</td>
            <td style={styles.textAlignRight}>{mockData?.totals?.totalQuantity}</td>
            <td style={styles.textAlignRight}>{mockData?.totals?.SGST}</td>
            <td style={styles.textAlignRight}>{mockData?.totals?.CGST}</td>
            <td style={styles.textAlignRight}>{mockData?.totals?.IGST}</td>
            <td style={styles.textAlignRight}>{mockData?.totals?.total}</td>
          </tr>
        </table>
      </Grid>
    </div>
  );

  return (
    <div className="form-container" style={{ margin: '5px 20px', fontSize: '10px' }} ref={reference}>
      <div>
        <Grid container spacing={2}>
          <Grid xs={4} style={{ maxHeight: '160px', padding: '10px 0' }}>
            <div>
              <p style={styles.pStyles}>Nike India Private Limited</p>
              <p style={styles.pStyles}>Ground & 1st Floor, Olympia building,</p>
              <p style={styles.pStyles}>No. 66/1, Bagmane Tech park, CV Raman Nagar, Bangalore 560093</p>
              <div style={styles.boxContainerWithMargin}>
                <p style={styles.pCondensedStyles}>
                  GST No:
                  {mockData.gstNumber}
                </p>
                <p style={styles.pCondensedStyles}>
                  CIN No:
                  {mockData.gstNumber}
                </p>
                <p style={styles.pCondensedStyles}>
                  PAN No:
                  {mockData.gstNumber}
                </p>
              </div>
            </div>
          </Grid>

          <Grid xs={5.5} style={{ maxHeight: '160px', padding: '10px 0' }}>
            <div>
              <u><h5 style={{ textAlign: 'center' }}>TRIPLICATE FOR CONSIGNOR</h5></u>
            </div>
          </Grid>

          <Grid xs={2.5} style={{ maxHeight: '160px', padding: '10px 0' }}>
            <div>
              <Swoosh width={140} height={55} fill="#000000" />
              <p style={styles.pCondensedStyles}>
                Invoice:
                {mockData.invoice}
              </p>
              <p style={styles.pCondensedStyles}>
                Invoice Date:
                {mockData.invoiceDate}
              </p>
              <h6>DELIVERY CHALLAN</h6>
              <strong><p>{mockData.division}</p></strong>
            </div>
          </Grid>

          <Grid xs={2} style={styles.boxContainer}>
            <div>
              <p style={styles.header}>CONSIGNEE</p>
              <p style={styles.pStyles}>TRACK AND FIELD GENERIC - MERCH</p>
              <p style={styles.pStyles}>Ground & 1st Floor, Olympia building,</p>
              <p style={styles.pStyles}>No.66/1 ,Bagmane Tech park, CV Raman nagar, Bangalore 560093</p>
              <p style={styles.pStyles}>INDIA</p>
            </div>
          </Grid>

          <Grid xs={2} style={styles.boxContainer}>
            <div>
              <p style={styles.header}>DELIVER GOODS TO</p>
              <p style={styles.pStyles}>Raul Trehan</p>
              <p style={styles.pStyles}>IOS Sports Entertainment</p>
              <p style={styles.pStyles}>406 DLF Courtyarn</p>
              <p style={styles.pStyles}>110017 NEW DELHI</p>
              <p style={styles.pStyles}>India</p>
              <br />
              <p>Ph#</p>
            </div>
          </Grid>

          <Grid xs={5.80} style={styles.boxContainer}>
            <div style={{ display: 'flex', gap: '30px' }}>
              <div>
                <p style={styles.pSpacedStyles}>
                  Customer Number:
                  {mockData.customerNumber}
                </p>
                <p style={styles.pSpacedStyles}>
                  Nike Order Number:
                  {mockData.orderNumber}
                </p>
                <p style={styles.pSpacedStyles}>
                  Nike Delivery Number:
                  {mockData.deliveryNumber}
                </p>
                <p style={styles.pSpacedStyles}>
                  Customer PO Number:
                  {mockData.poNumber}
                </p>
                <p style={styles.pSpacedStyles}>
                  Ordered By:
                  {mockData.orderedBy}
                </p>
                <p style={styles.pSpacedStyles}>
                  Ordered On:
                  {mockData.orderedDate}
                </p>
                <p style={styles.pSpacedStyles}>
                  Place of Supply:
                  {mockData.placeOfSupply}
                </p>
              </div>
              <div>
                <p style={styles.pSpacedStyles}>
                  PAN No:
                  {mockData.panNumber}
                </p>
                <p style={styles.pSpacedStyles}>
                  Consignee GSTIN:
                  {mockData.gstin}
                </p>
                <p style={styles.pSpacedStyles}>
                  Terms:
                  {mockData.terms}
                </p>
                <p style={styles.pSpacedStyles}>
                  PO Date:
                  {mockData.poDate}
                </p>
                <p style={styles.pSpacedStyles}>
                  Due Date:
                  {mockData.dueDate}
                </p>
                <p style={styles.pSpacedStyles}>
                  Currency:
                  {mockData.currency}
                </p>
                <p style={styles.pSpacedStyles}>
                  State Code:
                  {mockData.stateCode}
                </p>
              </div>
            </div>
          </Grid>

          <Grid xs={2} style={{ border: '1px solid black' }}>
            <div>
              <p style={styles.header}>TRANSPORT DETAILS</p>
              <p style={styles.pSpacedStyles}>
                Mode of Transport:
                {mockData.modeOfTransport}
              </p>
              <p style={styles.pSpacedStyles}>
                LR No:
                {mockData.lrNumber}
              </p>
              <p style={styles.pSpacedStyles}>
                Weight:
                {mockData.weight}
              </p>
              <p style={styles.pSpacedStyles}>
                Number of Cartons:
                {mockData.numberOfCartons}
              </p>
            </div>
          </Grid>

          <Grid xs={12} style={{ padding: '5px 7px 0 0' }}>
            <div>{SpanningTable}</div>
          </Grid>
          <Grid xs={12} style={{ height: '20px' }}>
            <div>{}</div>
          </Grid>
          <Grid xs={12}>
            <p>{mockData.cost}</p>
            <div style={{ display: 'flex', gap: '100px' }}>
              <div style={{ maxWidth: '400px' }}>
                <p>FOC Supply</p>
                <span style={{ display: 'flex', gap: '50px' }}>
                  <p>Shipped From:</p>
                  <p>{mockData.shippedFrom}</p>
                </span>
              </div>
              <div>
                <p>
                  Date Shipped:
                  {mockData.dateShipped}
                </p>
                <p>
                  Order Type:
                  {mockData.orderType}
                </p>

                <p>INTERNAL SAP REFERENCE NUMBER:</p>
              </div>
              <div>
                <p>FOR NIKE INDIA PVT. LTD.</p>
                <div style={{ height: '70px' }} />
                <p>Authorized Signatory</p>
              </div>
            </div>
          </Grid>
          <Grid xs={12}>
            <hr />
            <div style={{ display: 'flex', justifyContent: 'end', padding: '0 40px' }}>
              <div />
              <div>
                <p>E & OE</p>
                <p>
                  Page
                  {mockData.pageNumber}
                  {' '}
                  of
                  {mockData.totaPages}
                </p>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default ChallanForm;

ChallanForm.propTypes = {
  reference: object,
};

ChallanForm.defaultProps = {
  reference: {},
};
